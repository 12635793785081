import './LinkedInSettings.scss';

import { ReactElement, useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import { CommercialOutreachType, ConnectionStatus, DEFAULT_LOGIN_CREDENTIALS, LinkedInCredentials } from '../../../../types/BusinessTypes';
import { useOutsideClick } from '../../../../hooks/ClickHooks';

import OutlinePlusButton from '../../../common/buttons/composite/OutlinePlusButton';

import LinkedInConnectionStatusButton from '../buttons/LinkedInConnectionStatusButton';
import AuthenticateLinkedInModal from '../modals/AuthenticateLinkedInModal';

interface LinkedInSettingsProps {
  commercialOutreachSettings: CommercialOutreachType | null,
  updateAuthenticationDetails: (linkedInCredentials: LinkedInCredentials, connectionStatus: ConnectionStatus) => void
};

function LinkedInSettings({ commercialOutreachSettings, updateAuthenticationDetails }: LinkedInSettingsProps): ReactElement {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const openLoginModal = () => setShowLoginModal(true);
  const closeLoginModal = () => setShowLoginModal(false);
  const linkedInCredentials = commercialOutreachSettings && commercialOutreachSettings.linkedInCredentials ? commercialOutreachSettings.linkedInCredentials : DEFAULT_LOGIN_CREDENTIALS;
  const connectionStatus = commercialOutreachSettings && commercialOutreachSettings.connectionStatus ? commercialOutreachSettings.connectionStatus : ConnectionStatus.NotConnected;

  const addConnectionMessage = () => navigate('/commercial-outreach/settings/connection-message', { state: {}});

  const modalRef = useRef(null);
  useOutsideClick(modalRef, closeLoginModal);

  const confirmAuthenticationDetails = (loginCredentials: LinkedInCredentials, connectionStatus: ConnectionStatus) => {
    if (commercialOutreachSettings === null) {
      return;
    } 
    updateAuthenticationDetails(loginCredentials, connectionStatus)
  }

  const unlinkAccount = () => {
    confirmAuthenticationDetails(DEFAULT_LOGIN_CREDENTIALS, ConnectionStatus.NotConnected);
  }

  return (
    <section className={'linked-in-settings-content'}>
      { showLoginModal && (
        <AuthenticateLinkedInModal modalRef={modalRef} credentials={linkedInCredentials} close={closeLoginModal} confirm={confirmAuthenticationDetails} />
      )}
      <section className={'section-container'}>
        <h4 className={'input-title'}>{'Connect to your LinkedIn'}</h4>
        <h5 className={'input-subtitle'}>{'Log into the LinkedIn account you want to use so we can reach out to potential clients on your behalf'}</h5>
        <section className={'linked-in-settings-buttons'}>
          <LinkedInConnectionStatusButton openLoginModal={openLoginModal} connectionStatus={connectionStatus} unlinkAccount={unlinkAccount} />
          <OutlinePlusButton content={'Connection message'} onClick={addConnectionMessage} />
        </section>
      </section>
    </section>
  );
}

export default LinkedInSettings;