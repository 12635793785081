import { getData, postData } from './Api';

import { User, UserDetails } from '../types/UserTypes';
import { BusinessDetails } from '../types/BusinessTypes';
import { SuccessResponse } from './types/ResponseTypes';

const GetUserUrls = {
  GetUserUrl: '/api/user',
};

const PostUserUrls = {
  PostUpdateUserUrl: '/api/user/details/update',
}

const { GetUserUrl } = GetUserUrls;

const { PostUpdateUserUrl } = PostUserUrls;

// Request interfaces

/**
 * Request for updating a user and a business's details.
 */
interface UpdateUserRequest {
  userDetails: UserDetails,
  businessDetails: BusinessDetails,
  businessId: string,
}

// Response interfaces

/**
 * Request for getting the user from the server.
 */
interface UserResponse extends SuccessResponse {
  user: User | null,
}

// Get requests

/**
 * Retrieves the user from the server.
 */
export async function getUser(): Promise<UserResponse> {
  return getData(GetUserUrl);
}

/**
 * Updates the user's details.
 */
export async function postUpdateUserDetails(
  userDetails: UserDetails, businessId: string, businessDetails: BusinessDetails
): Promise<SuccessResponse> {
  const updateUserRequest: UpdateUserRequest = { userDetails, businessDetails, businessId };
  return postData<UpdateUserRequest, SuccessResponse>(PostUpdateUserUrl, updateUserRequest);
}