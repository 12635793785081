import { getData, postData } from './Api';
import { OvercostResponse, SuccessResponse } from './types/ResponseTypes';

import { LinkedInUser } from '../components/case-studies/commercial-outreach/CommercialOutreachConstants';

const GetCommercialOutreachUrls = {
  GetCommercialOutreachLinkedInUsersUrl: '/api/commercial-outreach/linkedin-users/',
}

const PostCommercialOutreachUrls = {
  SendCommercialOutreachLinkedInUsersUrl: '/api/commercial-outreach/linkedin-users/send',
  DeleteCommercialOutreachLinkedInUsersUrl: '/api/commercial-outreach/linkedin-users/deleteMany',
  LogCommercialOutreachLinkedInUsersRequestUrl: '/api/commercial-outreach/linkedin-users/log-request',
};

const {
  SendCommercialOutreachLinkedInUsersUrl,
  DeleteCommercialOutreachLinkedInUsersUrl,
  LogCommercialOutreachLinkedInUsersRequestUrl
} = PostCommercialOutreachUrls;

const {
  GetCommercialOutreachLinkedInUsersUrl,
} = GetCommercialOutreachUrls;

// Request interfaces

/**
 * Interface for request involved in updating a business's revenue estimator fields.
 */
interface SendLinkedInUsersRequest {
  businessId: string,
  linkedInUserIds: string[]
}

/**
 * Interface for request involved in updating a business's revenue estimator fields.
 */
interface DeleteLinkedInUsersRequest {
  linkedInUserIds: string[]
}

/**
 * Interface for request involved in updating a business's revenue estimator fields.
 */
interface PostLinkedInUsersToSlackRequest {
  businessId: string
}

// Response interfaces

/**
 * Interface for business subscription fetch response.
 */
interface FetchLinkedInUsersResponse extends SuccessResponse {
  linkedInUsers: LinkedInUser[],
}

// GET requests

/**
 * Get linkedIn user data from backend. Adds the business id as a request parameter.
 */
export async function getLinkedInUsers(businessId: string): Promise<FetchLinkedInUsersResponse> {
  return getData<FetchLinkedInUsersResponse>(`${GetCommercialOutreachLinkedInUsersUrl}${businessId}`);
}

// POST Requests

/**
 * Posts an update to business's commercial outreach settings field:
 * @param businessId: The business id to update.
 * @param commercialOutreachSettings: The commerical outreach settings to update on the business.
 */
export async function sendManyLinkedInUsers(
  businessId: string,
  linkedInUserIds: string[],
): Promise<OvercostResponse> {
  return postData<SendLinkedInUsersRequest, OvercostResponse>(
    SendCommercialOutreachLinkedInUsersUrl,
    { linkedInUserIds, businessId }
  );
}

/**
 * Posts an update to business's commercial outreach settings field:
 * @param businessId: The business id to update.
 * @param commercialOutreachSettings: The commerical outreach settings to update on the business.
 */
export async function deleteManyLinkedInUsers(
  linkedInUserIds: string[],
): Promise<SuccessResponse> {
  return postData<DeleteLinkedInUsersRequest, SuccessResponse>(
    DeleteCommercialOutreachLinkedInUsersUrl,
    { linkedInUserIds }
  );
}

/**
 * Posts an update to business's commercial outreach settings field:
 * @param businessId: The business id to update.
 * @param commercialOutreachSettings: The commerical outreach settings to update on the business.
 */
export async function postRequestOutreachToSlack(
  businessId: string,
): Promise<SuccessResponse> {
  return postData<PostLinkedInUsersToSlackRequest, SuccessResponse>(
    LogCommercialOutreachLinkedInUsersRequestUrl,
    { businessId }
  );
}

