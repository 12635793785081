export const createCsv = function (data: any) {
  if (!data || data.length === 0) {
    return;
  }
  
  // Empty array for storing the values
  const csvRows = [];

  // Headers is basically a keys of an object
  // which is id, name, and profession
  const headers = Object.keys(data[0]);

  // As for making csv format, headers must
  // be separated by comma and pushing it
  // into array
  csvRows.push(headers.join(','));

  for (const row of data) {
    const csvRow = [];
    for (const header of headers) {
      if (typeof row[header] === 'string') {
        // Use double quotes to wrap the columns, and replace any existing double quotes with single quotes in the column to prevent
        // issues where commas within the column mess the row structure.
        csvRow.push(`"${row[header].replaceAll('"', '\'')}"`);
      }
      else {
        csvRow.push(row[header]);
      }
    }
    csvRows.push(csvRow.join(','));
  }

  // Returning the array joining with new line 
  return csvRows.join('\n');
}


export const downloadCsv = function (data: any, csvName: string = 'download.csv') {
  if (!data) {
    return;
  }
  
  // Creating a Blob for having a csv file format 
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a')

  // Passing the blob downloading url 
  a.setAttribute('href', url)

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', csvName);

  // Performing a download with click
  a.click();
}