import { ActivityType, SubscriptionStatus, BusinessSubscription } from '../../../types/BusinessTypes';

import HANDWRITTEN_LETTER_ICON from './assets/handwritten-letter-icon.svg';
import REACTIVATION_ICON from './assets/reactivation-icon.svg';
import FOLLOW_UP_ICON from './assets/follow-up-icon.svg';

export const ActivityToCreditsMap = {
  [ActivityType.HandwrittenLetter]: 5,
  [ActivityType.Reactivation]: 1,
  [ActivityType.FollowUp]: 1,
}

export const ActivityToIconMap = {
  [ActivityType.HandwrittenLetter]: HANDWRITTEN_LETTER_ICON,
  [ActivityType.Reactivation]: REACTIVATION_ICON,
  [ActivityType.FollowUp]: FOLLOW_UP_ICON,
};

export enum AccountBillingTier {
  None = 'None',
  Basic = 'Basic',
  Established = 'Established',
  Scale = 'Scale',
  Enterprise = 'Enterprise',
}

export const AccountBillingTierToDisplayMap = {
  [AccountBillingTier.None]: 'none',
  [AccountBillingTier.Basic]: 'basic',
  [AccountBillingTier.Established]: 'established',
  [AccountBillingTier.Scale]: 'scale',
  [AccountBillingTier.Enterprise]: 'enterprise',
}

export const StripeBillingPrices = {
  [AccountBillingTier.Basic]: {
    type: AccountBillingTier.Basic,
    costPerMonth: 300,
    creditsPerMonth: 600,
    title: 'RexLocal Basic Plan - 600 credits per month',
    subtitle: '600 credits per month to use on any service to find, nurture leads, and reactivate clients. Un-used credits roll over each month.',
    highlighted: false,
    tag: null,
    subscriptionLink: 'https://buy.stripe.com/6oE01z9evaPm3qoaEI',
    subscriptionText: 'Subscribe',
  },
  [AccountBillingTier.Established]: {
    type: AccountBillingTier.Established,
    costPerMonth: 500,
    creditsPerMonth: 1200,
    title: 'RexLocal Established Plan - 1,200 credits per month',
    subtitle: '1,200 credits per month to use on any service to find, nurture leads, and reactivate clients. Un-used credits roll over each month. Priority support included.',
    highlighted: true,
    tag: 'Most popular',
    subscriptionLink: 'https://buy.stripe.com/cN28y53Ub7Dagda28b',
    subscriptionText: 'Subscribe',
  },
  [AccountBillingTier.Scale]: {
    type: AccountBillingTier.Scale,
    costPerMonth: 800,
    creditsPerMonth: 2400,
    title: 'RexLocal Scale Plan - 2,400 credits per month',
    subtitle: '2,400 credits per month to use on any service to find, nurture leads, and reactivate clients. Un-used credits roll over each month. Priority support included.',
    highlighted: false,
    tag: null,
    subscriptionLink: 'https://buy.stripe.com/8wM29H4Yf4qY4us002',
    subscriptionText: 'Subscribe',
  },
  [AccountBillingTier.Enterprise]: {
    type: AccountBillingTier.Enterprise,
    costPerMonth: null,
    creditsPerMonth: null,
    title: 'RexLocal Enterprise Plan - Unlimited credits per month',
    subtitle: 'Unlimited credits designed for all your franchisee locations. Enterprise level support, security, and user permissions.',
    highlighted: false,
    tag: null,
    subscriptionLink: 'https://calendly.com/lance-workrex/20min',
    subscriptionText: 'Contact us',
  },
}

export const EmptySubscription: BusinessSubscription = {
  status: SubscriptionStatus.Inactive,
  plan: AccountBillingTier.None,
  totalCredits: 0,
  usedCredits: 0,
}