import './StripePricingTableCard.scss';

import { ReactElement } from 'react';

import PrimaryBlueButton from '../../../common/buttons/PrimaryBlueButton';

interface StripePricingTableCardProps {
  highlighted: boolean,
  tag: string | null,
  title: string,
  subtitle: string,
  costPerMonth: number | null,
  subscriptionText: string,
  subscriptionLink: string | null,
}

function StripePricingTableCard({ highlighted, tag, title, subtitle, costPerMonth, subscriptionText, subscriptionLink } : Readonly<StripePricingTableCardProps>): ReactElement<StripePricingTableCardProps> {
  const goToLink = () => {
    if (subscriptionLink) {
      window.open(subscriptionLink, '_blank');
    }
  };
  return (
    <section className={`stripe-pricing-table-card ${highlighted ? 'highlighted' : ''}`}>
      <section className={'stripe-pricing-table-content'}>
        <header className={'card-header'}>
          <section className={'card-tag-container'}>
            { tag && <span className={'card-tag'}>{tag}</span> }
          </section>
          <h4 className={'card-title'}>{title}</h4>
          <p className={'card-subtitle'}>{subtitle}</p>
        </header>
        <footer className={'card-footer'}>
          <section className={'pricing-content-contaner'}>
            { costPerMonth && (
              <section className={'pricing-content'}>
                <span className={'price'}>
                  {`A$${costPerMonth}`}
                </span>
                <span className={'price-description'}>
                  {'per month'}
                </span>
              </section>
            )}
          </section>
          <PrimaryBlueButton content={subscriptionText} onClick={goToLink} />
        </footer>
      </section>
    </section>
  )
}

export default StripePricingTableCard;