import '../pages/CommercialOutreachManagePage.scss';

import { ReactElement, useState } from 'react';

import useAuth from '../../../../context/AuthContext';

import { FilterItem } from '../../../common/filters/types/FilterTypes';

import ConfirmSendOutreachModal from '../modals/ConfirmSendOutreachModal';

import { useBusinessLinkedInUsers } from '../hooks/CommercialOutreachHooks';
import { CommercialOutreachStatus, LinkedInUser } from '../CommercialOutreachConstants';
import { filterLinkedInUsers, searchLinkedInUsers } from '../helpers/CommercialOutreachFilterHelpers';
import CommercialOutreachEmptyPage from './CommercialOutreachEmptyPage';
import CommercialOutreachTableButtons from '../buttons/CommercialOutreachTableButtons';
import CommercialOutreachFilterBar from '../buttons/CommercialOutreachFilterBar';
import LoadingRing from '../../../common/loading/LoadingRing';
import CommercialOutreachTable from '../tables/CommercialOutreachTable';
import { sendManyLinkedInUsers } from '../../../../apis/CommercialOutreachApi';

// TODO: Simplify logic in this file.
function CommercialOutreachManagePage(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(true);
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [text, setText] = useState<string>('');
  const { cursor, setCursor, setLinkedInUsers, linkedInUsers, loading }  = useBusinessLinkedInUsers(selectedBusinessId);
  const [selectedLinkedInUsers, setSelectedLinkedInUsers] = useState<LinkedInUser[]>([]);
  
  const searchForLinkedInUsers = (searchText: string) => {
    setText(searchText);
    setCursor(0);
  };
  const closeConfirmModal = () => setConfirmModalOpen(false);
  const openConfirmModal = () => setConfirmModalOpen(true);
  const linkedInUserFilteredByStatus = linkedInUsers.filter(({ status }) => showCompleted || status !== CommercialOutreachStatus.Complete);
  const filteredLinkedInUsers = filterLinkedInUsers(linkedInUserFilteredByStatus, filters);
  const linkedInUsersToDisplay = searchLinkedInUsers(filteredLinkedInUsers, text);
  const showSendAllButton = linkedInUsersToDisplay.filter(
    ({ status }) => status !== CommercialOutreachStatus.Complete && status !== CommercialOutreachStatus.Requested).length > 0;

  const sendMessages = async () => {
    if (selectedBusinessId) {
      const linkedInUserIds = linkedInUsersToDisplay
        .filter((linkedInUser) => selectedLinkedInUsers.length > 0 ? selectedLinkedInUsers.includes(linkedInUser) : true)
        .filter(({ status }) =>
          status !== CommercialOutreachStatus.Complete && status !== CommercialOutreachStatus.Requested
        )
        .map(({ _id }) => _id);
      console.log(linkedInUserIds);
      if (linkedInUserIds.length === 0) {
        closeConfirmModal();
        return;
      }
      const newLinkedInUsers = linkedInUsers.map((linkedInUser) => {
        if (linkedInUserIds.includes(linkedInUser._id)) {
          return {
            ...linkedInUser,
            status: CommercialOutreachStatus.Requested
          };
        }
        return linkedInUser;
      });
      
      await sendManyLinkedInUsers(selectedBusinessId, linkedInUserIds)
      setLinkedInUsers(newLinkedInUsers);
    }
    closeConfirmModal();
  }
  
  const numLinkedInUsersSelected = linkedInUsersToDisplay
    .filter((linkedInUser) => selectedLinkedInUsers.includes(linkedInUser))
    .filter(
      ({ shouldSend, status }) => !shouldSend && status !== CommercialOutreachStatus.Complete && status !== CommercialOutreachStatus.Requested
    ).length;
  const totalAvailbleLettersCount = linkedInUsersToDisplay.filter(({ shouldSend, status }) =>
    !shouldSend && status !== CommercialOutreachStatus.Complete && status !== CommercialOutreachStatus.Requested
  ).length
  const linkedInUserCountText = `${linkedInUsers.length} total ${linkedInUsers.length === 1 ? 'linkedin user' : 'linkedin users'}`;
  const numLinkedInUsersSelectedText = numLinkedInUsersSelected > 0 ? ` (${numLinkedInUsersSelected})` : '';
  const quickSelectRows = (amount: number) => {
    const availableLinkedInUsers = linkedInUsersToDisplay.filter(linkedInUser => {
      return !linkedInUser.shouldSend && linkedInUser.status !== CommercialOutreachStatus.Complete && linkedInUser.status !== CommercialOutreachStatus.Requested && !selectedLinkedInUsers.includes(linkedInUser);
    });
    setSelectedLinkedInUsers([...selectedLinkedInUsers, ...availableLinkedInUsers.slice(0, amount)]);
  }
  const sendButtonText = numLinkedInUsersSelected === 1 ? `Send request${numLinkedInUsersSelectedText}` : `Send requests${numLinkedInUsersSelectedText}`;

  const hasLinkedInUsers = linkedInUsers && linkedInUsers.length > 0;
  return (
    <article className={'commercial-outreach-manage-page'}>
      { confirmModalOpen && <ConfirmSendOutreachModal cancel={closeConfirmModal} confirm={sendMessages} numLinkedInUsersSelected={numLinkedInUsersSelected === 0 ? totalAvailbleLettersCount : numLinkedInUsersSelected} /> }
      <section className={'page'}>
        <header className={'commercial-outreach-manage-page-header'}>
          <h2 className={'title'}>{'Manage your outreach'}</h2>
          <h3 className={'subtitle'}>{'Find and reach out to new clients that work at companies nearby using LinkedIn'}</h3>
          {hasLinkedInUsers && (
            <CommercialOutreachTableButtons
              sendButtonText={sendButtonText}
              openConfirmModal={openConfirmModal}
              showSendAllButton={showSendAllButton}
              showCompleted={showCompleted}
              setShowCompleted={setShowCompleted}
            />
          )}
          {hasLinkedInUsers && (
            <CommercialOutreachFilterBar
              searchForLinkedInUsers={searchForLinkedInUsers}
              text={text}
              linkedInUsers={linkedInUsers}
              linkedInUsersToDisplay={linkedInUsersToDisplay}
              filters={filters}
              setFilters={setFilters}
              quickSelectRows={quickSelectRows}
            />
          )}
          { hasLinkedInUsers && !loading && <section className={'linked-in-user-count'}>{linkedInUserCountText}</section> }
        </header>
        <section className={'page-content'}>
          {!hasLinkedInUsers && <CommercialOutreachEmptyPage />}
          {
            loading ? <LoadingRing color={'#000000'} /> : (
              <CommercialOutreachTable
                linkedInUsers={linkedInUsersToDisplay}
                setLinkedInUsers={setLinkedInUsers}
                cursor={cursor}
                setCursor={setCursor}
                selectedLinkedInUsers={selectedLinkedInUsers}
                setSelectedLinkedInUsers={setSelectedLinkedInUsers}
                hasMessagesToSend={showSendAllButton}
              />
            )
          }
        </section>
      </section>
    </article>
  );
}

export default CommercialOutreachManagePage;