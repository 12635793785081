import { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';

interface SendMessageModalProps {
  cancel: () => void,
  confirm: (setLoading: (loading: boolean) => void) => void,
};

function SendMessageModal({ cancel, confirm } : Readonly<SendMessageModalProps>) {
  const [loading, setLoading] = useState(false);
  return (
    <Modal additionalClassNames={['send-message-modal']}>
      <ConfirmationModalContent
        title={'Send Message'}
        subtitle={'Please confirm you would like to send out your messages.'}
        cancel={cancel}
        confirm={() => confirm(setLoading)}
        loading={loading}
      />
    </Modal>
  )
};

export default SendMessageModal;