import './PlanDetails.scss';

import { ReactElement, useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { AccountBillingTier } from '../../../../types/BusinessTypes';

import SubtleButton from '../../../common/buttons/SubtleButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';

import { AccountBillingTierToDisplayMap  } from '../AccountConstants';

import UpgradePlanModal from '../modals/UpgradePlanModal';

interface PlanDetailsProps {
  plan: AccountBillingTier,
}

function PlanDetails({ plan }: PlanDetailsProps): ReactElement<PlanDetailsProps> {
  const { user } = useAuth();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const stripeCustomerUrl = user?.stripeCustomerUrl;
  const stripeSubscriptionUrl = user?.stripeSubscriptionId;
  const stripeSubscriptionEndDate = user?.stripeSubscriptionEndDate;
  const upgradePlan = () => setShowUpgradeModal(true);
  const onClick  = () => {
    if (stripeCustomerUrl) {
      window.open(stripeCustomerUrl, '_blank');
    }
  }
  const hasPlan = !!stripeSubscriptionUrl && !!stripeSubscriptionEndDate;
  let planDetails = (
    <p className={'plan-details-summary'}>
      {'Click on the button below to get your plan setup.'}
    </p>
  );
  let planButtons = (
    <PrimaryButton
      additionalClassNames={['open-stripe-button']}
      content={'Manage'}
      onClick={onClick}
      disabled={!stripeCustomerUrl}
    />
  );
  if (hasPlan) {
    const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const renewalDate = new Date(stripeSubscriptionEndDate).toLocaleDateString(undefined, dateOptions);
    if (stripeSubscriptionEndDate <= Date.now()) {
      planDetails = (
        <p className={'plan-details-summary'}>
          {'Your plan expired on '}
          <b>{renewalDate}</b>
          {'. Renew your subscription below.'}
        </p>
      );
    }
    else if (plan === AccountBillingTier.None) {
      planDetails = (
        <p className={'plan-details-summary'}>
          {'You are not currently on a plan. Click the buttons below to get started with a plan.'}
        </p>
      );
    }
    else {
      planDetails = (
        <p className={'plan-details-summary'}>
          {'You are on the '}
          <b>{AccountBillingTierToDisplayMap[plan]}</b>
          {` plan. Your plan renews on `}
          <b>{renewalDate}</b>
          {'.'}
        </p>
      );
    }

    planButtons = (
      <>
        <PrimaryButton additionalClassNames={['upgrade-button']} content={'Upgrade'} onClick={upgradePlan} />
        <SubtleButton additionalClassNames={['open-stripe-button']} content={'Manage'} onClick={onClick} />
      </>
    );
  }
  return (
    <section className={'plan-details'}>
      { showUpgradeModal && <UpgradePlanModal closeModal={() => setShowUpgradeModal(false)} /> }
      <h4 className={'plan-details-title'}>{'Plan details'}</h4>
      {planDetails}
      <section className={'plan-buttons'}>
        {planButtons}
      </section>
    </section>
  );
}

export default PlanDetails;