export enum MessageStatus {
  Delivered = 'Delivered',
  NotSent = 'Not sent',
};

export enum Channel {
  SMS = 'SMS',
  Email = 'Email'
}

export enum RecipientType {
  Lead = 'Lead',
  Client = 'Client',
}

export interface Message {
  _id: string,
  recipientName: string,
  sendTo: string,
  messageStatus: MessageStatus,
  sendDate: number | null,
  channel: Channel,
  message: string,
  recipientType: RecipientType,
}