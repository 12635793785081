import './MessagePreviewPage.scss';

import { ReactElement, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import useAuth from '../../../context/AuthContext';
import { postRecoverClientMessagesUpdate } from '../../../apis/RecoverClientsApi';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';

import { Message } from './MessageConstants';

import MessagePreview from './previews/MessagePreview';

const DefaultOriginUrl = '/reactivate-clients/manage';

function MessagePreviewPage(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const initialMessages = state?.messages ?? null;
  const originUrl = state?.originUrl ?? DefaultOriginUrl;
  const back = () => navigate(originUrl);
  const [messages, setMessages] = useState<Message[]>(initialMessages);

  console.log(messages);
  if (!messages?.length) {
    return <Navigate to={originUrl} />;
  }

  const saveMessage = async () => {
    if (selectedBusinessId) {
      // TODO: Mark lost clients as tuning.
      await postRecoverClientMessagesUpdate(messages, selectedBusinessId);
    }
    back();
  };

  const totalMessages = messages.length;
  return (
    <article className={'message-preview-page'}>
      <section className={'page'}>
        <header className={'message-preview-page-header'}>
          <h2 className={'title'}>{'Preview your messages'}</h2>
          <h3 className={'subtitle'}>{'View and edit your message before approving it to be sent out.'}</h3>
        </header>
        <MessagePreview messages={messages} setMessages={setMessages} totalMessages={totalMessages} />
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <PrimaryButton content={'Save'} onClick={saveMessage} />
        </section>
      </footer>
    </article>
  );
};

export default MessagePreviewPage;