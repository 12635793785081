import './OutOfCreditsModal.scss';

import { ReactElement } from 'react';

import Modal from '../../../common/modals/Modal';

import StripePricingTable from '../tables/StripePricingTable';
import OutlineButton from '../../../common/buttons/OutlineButton';

interface OutOfCreditsModalProps {
  additionalClassNames?: string[],
  closeModal: () => void,
}

function OutOfCreditsModal({ additionalClassNames = [], closeModal } : Readonly<OutOfCreditsModalProps>): ReactElement<OutOfCreditsModalProps> {
  return (
    <Modal additionalClassNames={['out-of-credits-modal', ...additionalClassNames]}>
      <section className={'out-of-credits-modal-content'}>
        <header className={'modal-header'}>
          <h4 className={'modal-title'}>{'You\'ve run out of credits'}</h4>
          <h5 className={'modal-subtitle'}>{'Upgrade your plan to generate more revenue'}</h5>
        </header>
        <StripePricingTable />
        <section className={'close-button-container'}>
          <OutlineButton content={'Cancel'} onClick={closeModal} />
        </section>
      </section>
    </Modal>
  )
}

export default OutOfCreditsModal;