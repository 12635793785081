import './ReactivateClientConditionRow.scss';

import DateInput from '../../inputs/DateInput';
import Dropdown from '../../dropdowns/Dropdown';
import MultiSelectRow from '../MultiSelectRow';
import {
  RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP,
} from './FilterConstants';
import { REACTIVATE_CLIENT_CONDITION_OPTIONS_MAPPING, REACTIVATE_CLIENT_CONDITIONS_ATTRIBUTES, ReactivateLostClientsConditionAttributeKey, DateConditionKey } from '../../../../types/UserTypes';
import PrimaryInput from '../../inputs/PrimaryInput';
import { FilterType } from '../../filters/types/FilterTypes';
import { FilterConditionMap } from '../../filters/FilterConstants';

const { IsEmptyKey, IsNotEmptyKey } = DateConditionKey;
const { DATE } = FilterType;

const EMPTY_CONDITIONS = [IsEmptyKey, IsNotEmptyKey];

interface ReactivateClientConditionRowProps {
  attribute: ReactivateLostClientsConditionAttributeKey | null,
  setAttribute: (attribute: ReactivateLostClientsConditionAttributeKey) => void,
  condition: DateConditionKey | null,
  setCondition: (condition: DateConditionKey) => void,
  input: number | string,
  setInput: (input: number | string) => void,
  deleteRow: () => void,
}

function ReactivateClientConditionRow({ attribute, setAttribute, condition, setCondition, input, setInput, deleteRow } : Readonly<ReactivateClientConditionRowProps>) {
  const showConditions = attribute !== null;
  const showInput = condition !== null && !EMPTY_CONDITIONS.includes(condition);

  // Need to figure out a better way to do this.
  const attributeType = showConditions ? RECOVER_CLIENTS_FILTER_ATTRIBUTES_MAP[attribute].type : '';

  const dateAttribute = attributeType === DATE;
  const dateInput = dateAttribute && input
    ? new Date(input)
    : null;
  
  let inputField = null;
  if (showConditions && showInput) {
    const conditionObj = FilterConditionMap[attributeType][condition];
    if (conditionObj && conditionObj.valueType === DATE) {
      inputField = <DateInput selectedDate={dateInput} setDate={setInput} />
    }
    else {
      inputField = <PrimaryInput placeholder={'Add value'} text={`${input}`} setText={setInput} />;
    }
  }

  return (
    <MultiSelectRow deleteRow={deleteRow}>
      <section className={'multi-select-row-grid'}>
        <Dropdown defaultDisplayText={'Select attribute'} options={REACTIVATE_CLIENT_CONDITIONS_ATTRIBUTES} selected={attribute} select={setAttribute} additionalClassNames={['reactivate-client-settings-attribute-dropdown']} />
        { showConditions && attributeType && <Dropdown defaultDisplayText={'Select condition'} options={REACTIVATE_CLIENT_CONDITION_OPTIONS_MAPPING[attributeType]} selected={condition} select={setCondition} additionalClassNames={['reactivate-client-settings-conditions-dropdown']} /> }
        { showInput && inputField }
      </section>
    </MultiSelectRow>
  );
}

export default ReactivateClientConditionRow;