import './AuthenticateLinkedInModal.scss';

import { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import PrimaryInput from '../../../common/inputs/PrimaryInput';
import { ConnectionStatus, LinkedInCredentials } from '../../../../types/BusinessTypes';

interface AuthenticateLinkedInModalProps {
  credentials: LinkedInCredentials,
  close: () => void,
  confirm: (loginCredentials: LinkedInCredentials, connectionStatus: ConnectionStatus) => void,
  modalRef: React.MutableRefObject<null>,
};

function AuthenticateLinkedInModal({ credentials, close, confirm, modalRef } : Readonly<AuthenticateLinkedInModalProps>) {
  const [linkedInCredentials, setLinkedInCredentials] = useState(credentials);

  const setEmail = (email: string) => {
    setLinkedInCredentials({ ...linkedInCredentials, email });
  }

  const setPassword = (password: string) => {
    setLinkedInCredentials({ ...linkedInCredentials, password });
  }

  const authenticateLogin = () => {
    confirm(linkedInCredentials, ConnectionStatus.Processing);
    close();
  }

  return (
    <Modal additionalClassNames={['auth-linkedin-modal']}>
      <section ref={modalRef} className={'auth-linkedin-modal-content'} >
        <h4 className={'title'}>{'Log into your LinkedIn account'}</h4>
        <h5 className={'subtitle'}>{'LinkedIn messages are sent out via your profile to achieve the best results'}</h5>
        <section className={'input-container'}>
          <label className={'input-label'} htmlFor={'number-of-clients-input'}>{'Email'}</label>
          <PrimaryInput text={linkedInCredentials.email} setText={setEmail} placeholder={'email address'} />
        </section>
        <section className={'input-container'}>
          <label className={'input-label'} htmlFor={'number-of-clients-input'}>{'Password'}</label>
          <PrimaryInput text={linkedInCredentials.password} setText={setPassword} inputType={'password'} placeholder={'password'} />
        </section>
        <section className={'buttons'}>
          <OutlineButton content={'Cancel'} onClick={close} />
          <PrimaryButton content={'Confirm'} onClick={authenticateLogin} />
        </section>
      </section>
    </Modal>
  )
};

export default AuthenticateLinkedInModal;