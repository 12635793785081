import TICK_ICON from './assets/tick-icon.svg';
import SENT_ICON from './assets/sent-icon.svg';
import NEW_LEADS_ICON from './assets/new-leads-icon.svg';
import CLIENT_ICON from './assets/client-icon.svg';
import DOLLAR_ICON from './assets/dollar-icon.svg';

import {
  HandwrittenLetterMetrics,
  ReactivateLostClientsMetrics,
  CommercialOutreachMetrics
} from '../../../types/MetricTypes';

export enum CaseStudyCategory {
  FindNewClients = 'Find new clients',
  RetainYourClients = 'Retain your clients',
};

export enum CaseStudyType {
  LivingNearby = 'Living nearby',
  ReactivateLostClients = 'Reactivate lost clients',
  CommercialOutreach = 'Commerical outreach',
}

interface CaseStudyItem {
  type: CaseStudyType
  title: string,
  subtitle: string,
  previewUrl: string,
  setupUrl: string,
}

export const CaseStudy: Record<CaseStudyType, CaseStudyItem> = {
  [CaseStudyType.LivingNearby]: {
    type: CaseStudyType.LivingNearby,
    title: 'Find new clients',
    subtitle: 'Find and reach out to new clients that have recently purchased or rented properties nearby using handwritten letters',
    previewUrl: 'https://www.youtube.com/embed/1sVE8kr4XZY?si=_dVkoL1RX-muI4iK',
    setupUrl: '/living-nearby/manage',
  },
  [CaseStudyType.ReactivateLostClients]: {
    type: CaseStudyType.ReactivateLostClients,
    title: 'Reactivate lost clients',
    subtitle: 'Detect clients that may be lost and attempt to rescue them with an offer',
    previewUrl: 'https://www.youtube.com/embed/4SMO_jEHlfU?si=wE8M61FxtfrGnadb',
    setupUrl: '/reactivate-clients/manage',
  },
  [CaseStudyType.CommercialOutreach]: {
    type: CaseStudyType.CommercialOutreach,
    title: 'Find new clients',
    subtitle: 'Find and reach out to new clients that work at companies nearby using LinkedIn',
    previewUrl: 'https://www.youtube.com/embed/OOfuX2froQw?si=FNu-MUFVeEbeaRG8',
    setupUrl: '/commercial-outreach/manage',
  },
}

export interface DashboardMetric {
  label: string,
  icon: string,
  tooltipText: string | null,
  fieldName: string,
  isEditable?: boolean,
}

export const HandwrittenLetterMetricItems: DashboardMetric[] = [
  {
    label: 'Available',
    icon: TICK_ICON,
    tooltipText: 'Number of new eligible addresses you can send letters to',
    fieldName: 'available',
  },
  {
    label: 'Sent',
    icon: SENT_ICON,
    tooltipText: 'Number of letters which have been delivered to your selected addresses',
    fieldName: 'sent',
  },
  {
    label: 'New leads',
    icon: NEW_LEADS_ICON,
    tooltipText: 'Number of new leads for your business as a result of sent letters',
    fieldName: 'newLeads',
  },
  {
    label: 'Estimated revenue',
    icon: DOLLAR_ICON,
    tooltipText: 'Estimated revenue that can be generated from your leads',
    fieldName: 'estimatedRevenue',
    isEditable: true
  },
];

export const ReactivateLostClientsMetricItems: DashboardMetric[] = [
  {
    label: 'Available',
    icon: TICK_ICON,
    tooltipText: 'Number of lost clients eligible for reactivation ',
    fieldName: 'available',
  },
  {
    label: 'Sent',
    icon: SENT_ICON,
    tooltipText: 'Number of messages sent to lost clients',
    fieldName: 'sent',
  },
  {
    label: 'Reactivated clients',
    icon: CLIENT_ICON,
    tooltipText: 'Number of clients that has been successfully reactivated',
    fieldName: 'reactivatedClients',
  },
  {
    label: 'Revenue',
    icon: DOLLAR_ICON,
    tooltipText: 'Revenue from clients successfully reactivated',
    fieldName: 'revenue',
  }
];

export const CommercialOutreachMetricItems: DashboardMetric[] = [
  {
    label: 'Available',
    icon: TICK_ICON,
    tooltipText: 'Number of remaining people that work at companies nearby to your business',
    fieldName: 'available',
  },
  {
    label: 'Sent',
    icon: SENT_ICON,
    tooltipText: 'Number of people you’ve reached out to that work nearby',
    fieldName: 'sent',
  },
  {
    label: 'New Leads',
    icon: NEW_LEADS_ICON,
    tooltipText: 'Number of people that have signed up to your corporate perks program',
    fieldName: 'newLeads',
  },
  {
    label: 'Estimated revenue',
    icon: DOLLAR_ICON,
    tooltipText: 'Estimated revenue that can be generated from your leads',
    fieldName: 'estimatedRevenue',
    isEditable: true,
  }
];

export const DefaultHandwrittenLetterMetrics: HandwrittenLetterMetrics = {
  available: {
    value: '0',
    lastUpdateDate: null,
  },
  sent: {
    value: '0',
    lastUpdateDate: null,
  },
  newLeads: {
    value: '0',
    lastUpdateDate: null,
  },
  estimatedRevenue: {
    value: '$0',
    lastUpdateDate: null,
  },
}

export const DefaultReactivateLostClientsMetrics: ReactivateLostClientsMetrics = {
  available: {
    value: '0',
    lastUpdateDate: null,
  },
  sent: {
    value: '0',
    lastUpdateDate: null,
  },
  reactivatedClients: {
    value: '0',
    lastUpdateDate: null,
  },
  revenue: {
    value: '$0',
    lastUpdateDate: null,
  },
}

export const DefaultCommercialOutreachMetrics: CommercialOutreachMetrics = {
  available: {
    value: '0',
    lastUpdateDate: null,
  },
  sent: {
    value: '0',
    lastUpdateDate: null,
  },
  newLeads: {
    value: '0',
    lastUpdateDate: null,
  },
  estimatedRevenue: {
    value: '$0',
    lastUpdateDate: null,
  },
}

export const CaseStudies = [
  {
    key: 1,
    title: 'Find clients that live nearby',
    descriptions: [
      'We find who has recently purchased or rented a property nearby',
      'We send them a handwritten letter to them on your behalf',
    ],
    previewUrl: 'https://www.youtube.com/embed/1sVE8kr4XZY?si=_dVkoL1RX-muI4iK',
    navigationUrl: '/living-nearby/about',
    type: CaseStudyCategory.FindNewClients,
  },
  {
    key: 3,
    title: 'Find clients that work nearby',
    descriptions: [
      'We find companies nearby, and the people that work there',
      'We connect to your LinkedIn and message them on your behalf',
    ],
    previewUrl: 'https://www.youtube.com/embed/OOfuX2froQw?si=FNu-MUFVeEbeaRG8',
    navigationUrl: '/commercial-outreach/about',
    type: CaseStudyCategory.FindNewClients,
  },
  {
    key: 2,
    title: 'Bring back your lost clients',
    descriptions: [
      'We find valuable clients that haven\'t returned for a long time',
      'We send these clients an SMS message with an incentive to come back',
    ],
    previewUrl: 'https://www.youtube.com/embed/4SMO_jEHlfU?si=wE8M61FxtfrGnadb',
    navigationUrl: '/reactivate-clients/about',
    type: CaseStudyCategory.RetainYourClients,
  },
];

export const DefaultLeadToClientProportion = '800';
export const DefaultRevenuePerClient = '50';