import './FineTuneModal.scss';

import { useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { postQueueMessageToFineTune, postStaticMessageGeneration } from '../../../../apis/MessageApi';
import { Message } from '../MessageConstants';

import Modal from '../../../common/modals/Modal';
import TextAreaInput from '../../../common/inputs/TextAreaInput';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import IconButton from '../../../common/buttons/IconButton';

import CHECKED_CHECKBOX_ICON from '../assets/checked-checkbox.svg';
import UNCHECKED_CHECKBOX_ICON from '../assets/unchecked-checkbox.svg';

interface FineTuneModalProps {
  messages: Message[],
  close: () => void,
  confirm: () => void,
};

function FineTuneModal({ messages, close, confirm } : Readonly<FineTuneModalProps>) {
  const { selectedBusinessId } = useAuth();
  const [fineTunePrompt, setFineTunePrompt] = useState('');
  const [disableAITuning, setDisableAITuning] = useState(false);
  const toggleDisableAITuning = () => setDisableAITuning(!disableAITuning)

  const confirmTuning = async () => {
    if (!selectedBusinessId) {
      return;
    }

    const messageIds = messages.map(({ _id }) => _id);
    if (disableAITuning) {
      await postStaticMessageGeneration(fineTunePrompt, messageIds, selectedBusinessId);
    }
    else {
      await postQueueMessageToFineTune(fineTunePrompt, messages);
    }
    
    confirm();
  }

  const checkboxIcon = disableAITuning ? CHECKED_CHECKBOX_ICON : UNCHECKED_CHECKBOX_ICON;
  return (
    <Modal additionalClassNames={['fine-tune-modal']}>
      <section className={'fine-tune-modal-content'} >
        <h4 className={'title'}>{'Make adjustments to your message'}</h4>
        <h5 className={'subtitle'}>{'Fine tune your message by changing or replacing details in the message.'}</h5>
        <section className={'disable-ai'}>
          <IconButton
            onClick={toggleDisableAITuning}
            icon={checkboxIcon}
            additionalClassNames={['clickable']}
          />
          <span className={'disable-ai-text'}>{'Use static message without AI tuning'}</span>
        </section>
        <TextAreaInput text={fineTunePrompt} setText={setFineTunePrompt} placeholder={'Start typing your desired changes here...'} />
        <section className={'buttons'}>
          <OutlineButton content={'Cancel'} onClick={close} />
          <PrimaryButton content={'Confirm'} onClick={confirmTuning} />
        </section>
      </section>
    </Modal>
  )
};

export default FineTuneModal;