import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoginMethod } from './LoginConstants';

import VerifyPage from './VerifyPage';
import LoginChannelManager from './LoginChannelManager';

function LoginViewManager() {
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get('verification_code') || '';
  const loginMethodParam = searchParams.get('login_method') || LoginMethod.SMS;
  const loginDetailsParam = searchParams.get('login_details') || '';
  const isAutoLogin = !!(verificationCode && loginMethodParam && loginDetailsParam);

  const [showingVerifyPage, setShowingVerifyPage] = useState(isAutoLogin);
  const [loginDetail, setLoginDetail] = useState(loginDetailsParam);
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(loginMethodParam as LoginMethod);

  if (showingVerifyPage) {
    return (
      <VerifyPage
        loginDetail={loginDetail}
        setShowingVerifyPage={setShowingVerifyPage}
        loginMethod={loginMethod}
      />
    );
  }
  return (
    <LoginChannelManager
      setShowingVerifyPage={setShowingVerifyPage}
      loginDetail={loginDetail}
      setLoginDetail={setLoginDetail}
      loginMethod={loginMethod}
      setLoginMethod={setLoginMethod}
    />
  );
}

export default LoginViewManager;