import '../common/DashboardMetricItem.scss';

import { ReactElement } from 'react';

import InformationIconTooltip from '../../../common/tooltips/InformationIconTooltip';

import { getLastUpdateDate } from '../helpers/MetricHelper';

import UPDATE_ICON from '../assets/last-update-date-icon.svg';
import SETTINGS_ICON from '../assets/settings-icon.svg';
import IconButton from '../../../common/buttons/IconButton';

interface DashboardMetricProps {
  label: string,
  count: number | string,
  icon: string,
  lastUpdateDate: number | null,
  tooltipText: string | null,
  isEditable: boolean | undefined,
  openSettingsModal: () => void,
}

function DashboardMetricItem({ label, count, icon, lastUpdateDate, tooltipText, isEditable, openSettingsModal } : Readonly<DashboardMetricProps>): ReactElement<DashboardMetricProps> {
  const lastUpdateText = lastUpdateDate ? getLastUpdateDate(lastUpdateDate) : '';

  return (
    <section className={'dashboard-metric-item'}>
      <section className={'metric'}>
        <section className={'metric-icon'}>
          <img className={'icon'} src={icon} alt={label} />
        </section>
        <section className={'metric-count'}>
          <section className={'metric-label'}>
            <span className={'metric-text'}>{label}</span>
            { tooltipText && <InformationIconTooltip tooltipText={tooltipText} />}
          </section>
          <section className={'metric-count'}>
            {count}
          </section>
        </section>
      </section>
      <section className={`footer ${lastUpdateDate ? 'space-between-icons' : ''}`}>
        { lastUpdateDate && (
          <section className={'last-update-container'}>
            <img className={'update-icon'} src={UPDATE_ICON} alt={'Last updated icon'} />
            <span className={'last-update-text'}>{`Updated ${lastUpdateText}`}</span>
          </section>
        )}
        {isEditable && <IconButton additionalClassNames={['dashboard-settings-icon']} icon={SETTINGS_ICON} onClick={openSettingsModal} />}
      </section>
    </section>
  );
}

export default DashboardMetricItem;