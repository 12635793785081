import { ConnectionStatus } from '../../../types/BusinessTypes';
import { Location } from '../../../types/GeoTypes';
import { OptionType } from '../../common/dropdowns/DropdownConstants';
import { FilterableItem, FilterItemWithKey, FilterType } from '../../common/filters/types/FilterTypes';

const { STRING, NUMBER } = FilterType;

export enum CommercialOutreachStatus {
  Available = 'Available',
  Requested = 'Requested',
  Complete = 'Complete',
};

export interface LinkedInUser extends FilterableItem {
  _id: string,
  firstName: string,
  lastName: string,
  companyName: string,
  addressLocation?: Location | null,
  status: CommercialOutreachStatus,
  dateOfBirth: number | null,
  age: number | null,
  linkedInUrl: string,
  emailAddress: string,
};

export enum LinkedInUserFilterAttributeKey {
  FirstNameKey = 'firstName',
  LastNameKey = 'lastName',
  CompanyNameKey = 'companyName',
  StatusKey = 'status',
  EmailAddressKey = 'emailAddress',
  AgeKey = 'age'
}

export enum LinkedInUserFilterAttributeText {
  FirstNameText = 'First Name',
  LastNameText = 'Last Name',
  CompanyNameText = 'Company',
  StatusText = 'Status',
  EmailAddressText = 'Email',
  AgeText = 'Age'
};

export type LinkedInUserFilterType = FilterItemWithKey<LinkedInUserFilterAttributeKey>;

export const LinkedInUserFilterAttributeMap = {
  firstName: {
    key: LinkedInUserFilterAttributeKey.FirstNameKey,
    displayText: LinkedInUserFilterAttributeText.FirstNameText,
    type: STRING
  },
  lastName: {
    key: LinkedInUserFilterAttributeKey.LastNameKey,
    displayText: LinkedInUserFilterAttributeText.LastNameText,
    type: STRING
  },
  companyName: {
    key: LinkedInUserFilterAttributeKey.CompanyNameKey,
    displayText: LinkedInUserFilterAttributeText.CompanyNameText,
    type: STRING
  },
  status: {
    key: LinkedInUserFilterAttributeKey.StatusKey,
    displayText: LinkedInUserFilterAttributeText.StatusText,
    type: STRING
  },
  emailAddress: {
    key: LinkedInUserFilterAttributeKey.EmailAddressKey,
    displayText: LinkedInUserFilterAttributeText.EmailAddressText,
    type: STRING
  },
  age: {
    key: LinkedInUserFilterAttributeKey.AgeKey,
    displayText: LinkedInUserFilterAttributeText.AgeText,
    type: NUMBER
  },
};

export const LinkedInUserFilterAttributes = Object.values(LinkedInUserFilterAttributeMap);
export const LinkedInUserFilterAttributeOptions: OptionType<LinkedInUserFilterAttributeKey>[] = LinkedInUserFilterAttributes.map(({ key, displayText }) => ({ value: key, displayText }));

export const DEFAULT_COMMERCIAL_OUTREACH_SETTINGS = {
  suburbs: [],
  linkedInCredentials: {
    email: '',
    password: ''
  },
  connectionStatus: ConnectionStatus.NotConnected,
  connectionMessage: '',
}