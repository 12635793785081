import './CommercialOutreachConnectionMessagePage.scss';

import { ReactElement, useState } from 'react';

import { useBusinessCommercialOutreachSettings } from '../hooks/CommercialOutreachHooks';
import useAuth from '../../../../context/AuthContext';

import TextAreaInput from '../../../common/inputs/TextAreaInput';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router';
import { postUpdateBusinessCommercialOutreachLinkedInSettings } from '../../../../apis/BusinessApi';

const PLACEHOLDER_CONNECTION_STRING = 'Enter your connection message here. This is the message we will send when we first reach out to potential customers';
const DefaultOriginUrl = '/commercial-outreach/settings';

function CommercialOutreachConnectionMessagePage(): ReactElement {
  const { selectedBusinessId, business, setSelectedBusiness } = useAuth();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const originUrl = state?.originUrl ?? DefaultOriginUrl;
  const back = () => navigate(originUrl);
  const { commercialOutreachSettings, setCommercialOutreachSettings } = useBusinessCommercialOutreachSettings(selectedBusinessId);
  const connectionMessage = commercialOutreachSettings ? commercialOutreachSettings.connectionMessage : '';

  const updateConnectionMessage = (connectionMessage: string) => {
    if (commercialOutreachSettings === null || connectionMessage.length > 300) {
      return;
    }
    setCommercialOutreachSettings({ ...commercialOutreachSettings, connectionMessage });
  }

  const saveConnectionMessage = async () => {
    if (commercialOutreachSettings === null) {
      return;
    }
    setLoading(true);
    setCommercialOutreachSettings(commercialOutreachSettings);
    if (selectedBusinessId && business) {
      await postUpdateBusinessCommercialOutreachLinkedInSettings(selectedBusinessId, commercialOutreachSettings);
      setSelectedBusiness({ ...business, commercialOutreachSettings: commercialOutreachSettings });
    }
    setLoading(false);
  };

  return (
    <article className={'connection-message-settings-page'}>
      <section className={'page'}>
        <header className={'connection-message-settings-page-header'}>
          <h2 className={'title'}>{'Add connection'}</h2>
          <h3 className={'subtitle'}>{'Send your LinkedIn connection with a personalised message (recommended)'}</h3>
        </header>
        <section className={'page-content'}>
          <section className={'connection-message-input'}>
            <TextAreaInput text={connectionMessage} setText={updateConnectionMessage} placeholder={PLACEHOLDER_CONNECTION_STRING} />
            <span className={'connection-message-length'}>{`${connectionMessage.length}/300`}</span>
          </section>
          <footer className={'footer-buttons'}>
            <OutlineButton content={'Back'} onClick={back} />
            <PrimaryButton content={'Save'} onClick={saveConnectionMessage} loading={loading} />
          </footer>
        </section>
      </section>
    </article>
  )
}

export default CommercialOutreachConnectionMessagePage;