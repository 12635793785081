import './ConnectLinkedInAccountButton.scss';

import { ReactElement } from 'react';

import OutlineButton from '../../../common/buttons/OutlineButton';

import LINK_ICON from '../../assets/link-icon.svg';

interface ConnectLinkedInAccountButtonProps {
  openLoginModal: () => void
};

function ConnectLinkedInAccountButton({ openLoginModal } : Readonly<ConnectLinkedInAccountButtonProps>): ReactElement {
  const connectLinkedInAccountButtonContent = (
    <section className={'linked-in-connect-button'}>
      <span>{'Connect a LinkedIn Account'}</span>
      <img src={LINK_ICON} alt={''} />
    </section>
  )

  return (
    <OutlineButton content={connectLinkedInAccountButtonContent} onClick={openLoginModal} />
  )
}

export default ConnectLinkedInAccountButton;