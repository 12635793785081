import 'react-phone-input-2/lib/style.css';
import './LoginChannelManager.scss';

import { LoginMethod } from './LoginConstants';

import ChangeLoginMethodButton from './common/ChangeLoginMethodButton';
import LoginWrapper from './common/LoginWrapper';
import LoginHeader from './common/LoginHeader';
import SwapLoginSignUp from './common/SwapLoginSignUp';
import MobileLoginInput from './channels/MobileLoginInput';
import EmailLoginInput from './channels/EmailLoginInput';

const { SMS, EMAIL } = LoginMethod;

interface LoginChannelManagerProps {
  loginDetail: string,
  loginMethod: LoginMethod,
  setLoginDetail: React.Dispatch<React.SetStateAction<string>>,
  setLoginMethod: React.Dispatch<React.SetStateAction<LoginMethod>>
  setShowingVerifyPage: React.Dispatch<React.SetStateAction<boolean>>
}

function LoginChannelManager({ loginDetail, loginMethod, setLoginDetail, setLoginMethod, setShowingVerifyPage } : LoginChannelManagerProps) {
  const smsMethodSelected = loginMethod === SMS;
  const loginChannelInput = smsMethodSelected
    ? <MobileLoginInput loginDetail={loginDetail} setLoginDetail={setLoginDetail} setShowingVerifyPage={setShowingVerifyPage} />
    : <EmailLoginInput loginDetail={loginDetail} setLoginDetail={setLoginDetail} setShowingVerifyPage={setShowingVerifyPage} />
  
  const otherLoginMethod = smsMethodSelected ? EMAIL : SMS;
  const optionText = smsMethodSelected ? 'Continue with Email' : 'Continue with SMS';

  const updateLoginMethod = () => {
    setLoginDetail('');
    setLoginMethod(otherLoginMethod);
  }
  return (
    <LoginWrapper>
      <LoginHeader title={'Welcome'} subtitle={'Login to RexLocal'} />
      <section className={'channel-manager-container'}>
        {loginChannelInput}
        <SwapLoginSignUp />
        <section className={'sign-in-options-divider'}>
          <p className={'sign-in-options-divider-text'}>{'OR'}</p>
        </section>
        <section className={'sign-in-options-container'}>
          <ChangeLoginMethodButton optionText={optionText} updateLoginMethod={updateLoginMethod} />
        </section>
      </section>
    </LoginWrapper>
  )
}

export default LoginChannelManager;