import './StripePricingTable.scss';

import { ReactElement } from 'react';

import { StripeBillingPrices } from '../AccountConstants';

import StripePricingTableCard from '../common/StripePricingTableCard';

function StripePricingTable(): ReactElement {
  const pricingTable = Object
    .values(StripeBillingPrices)
    .map(({ type, highlighted, tag, title, subtitle, costPerMonth, subscriptionText, subscriptionLink }) => {
      return (
        <StripePricingTableCard
          key={`stripe-pricing-${type}`}
          highlighted={highlighted}
          tag={tag}
          title={title}
          subtitle={subtitle}
          costPerMonth={costPerMonth}
          subscriptionText={subscriptionText}
          subscriptionLink={subscriptionLink}
        />
      );
    });

  return (
    <section className={'stripe-pricing-table'}>
      {pricingTable}
    </section>
  )
}

export default StripePricingTable;