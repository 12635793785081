import './ProgressBar.scss';

import { ReactElement } from 'react';

interface ProgressBarProps {
  completionNumber: number,
  total: number,
  height: number,
  backgroundColor: string,
  borderColor?: string,
  barColor: string,
}

function ProgressBar({ completionNumber, total, height, backgroundColor, barColor, borderColor = 'transparent' } : Readonly<ProgressBarProps>): ReactElement<ProgressBarProps> {
  const percentComplete = (completionNumber / total) * 100;
  return (
    <section className={'progress-bar'} style={{ backgroundColor, borderColor }}>
      <section className={'bar'} style={{ height: `${height}px`, width: `${percentComplete}%`, backgroundColor: barColor }} />
    </section>
  );
}

export default ProgressBar;