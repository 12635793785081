import './AddressSearchBar.scss';

import { ReactElement, useState, useEffect } from 'react';

import AutoComplete from 'react-google-autocomplete';
import RemovableItem from '../../common/rows/RemovableItem';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBN79DWVz6nD-htQLf37KfKv3Bnd4cbkxk';

interface GoogleAddressObject {
  formatted_address: string,
}

interface AddressSearchBarProps {
  suburbs: string[],
  setSuburbs: (suburbs: string[]) => void
};

function AddressSearchBar({ suburbs, setSuburbs }: AddressSearchBarProps): ReactElement {
  const [placeInput, setPlaceInput] = useState('');
  useEffect(() => {
    if (placeInput.length > 0 && !suburbs.includes(placeInput) && suburbs.length < 5) {
      setSuburbs([...suburbs, placeInput]);
      setPlaceInput('');
    }
  }, [placeInput, setSuburbs, suburbs])

  const onPlaceSelected = (place: GoogleAddressObject) => setPlaceInput(place.formatted_address);

  const suburbsList = suburbs.map((place, index) => {
    const deleteIndex = () => setSuburbs(suburbs.filter((_, i) => i !== index));
    return (
      <RemovableItem additionalClassNames={['suburb']} removeItem={deleteIndex} key={place}>
        <span className={'index'}>{index + 1}</span>
        <span className={'name'}>{place}</span>
      </RemovableItem>
    );
  });
  return (
    <section className={'address-search-content'}>
      <section className={'section-container'}>
        <h4 className={'input-title'}>{'What area would you like to target?'}</h4>
        <h5 className={'input-subtitle'}>{'Add up to 5 suburbs for this outreach'}</h5>
        <section className={'input-container'}>
          <label className={'input-label'} htmlFor={'number-of-clients-input'}>{'Suburb'}</label>
          <AutoComplete apiKey={GOOGLE_MAPS_API_KEY} onPlaceSelected={onPlaceSelected} placeholder={'Start typing suburb to search'} />
        </section>
        <section className={'suburbs-list'}>
          {suburbsList}
        </section>
      </section>
    </section>
  );
}

export default AddressSearchBar;