import './RecoverClientsManagePage.scss';

import { ReactElement, useCallback, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useAuth from '../../../context/AuthContext';
import { RecoverClientsFilterType } from '../../common/multi-select-rows/filters/FilterConstants';
import { EnrichedRecoverClientMessage, FLATFILE_UPLOAD_TYPE, FlatFileTypeKey } from './RecoverClientsConstants';
import { filterRecoverClientMessages } from '../../common/multi-select-rows/filters/FilterHelpers';
import { MessageStatus } from './MessageConstants';
import { getRecoverClientMessagesForBusiness, postRecoverClientsSendMany } from '../../../apis/RecoverClientsApi';

import Dropdown from '../../common/dropdowns/Dropdown';
import RecipientFlatfileImport from '../../../utils/flatfile/RecipientFlatfileImport';
import InteractionFlatfileImport from '../../../utils/flatfile/InteractionFlatfileImport';
import RecoverClientsTable from './tables/RecoverClientsTable';
import SubtleButton from '../../common/buttons/SubtleButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';

import RecoverClientsFilter from '../common/RecoverClientsFilter';
import SendMessageModal from './modals/SendMessageModal';
import RecoverClientsEmptyPage from './RecoverClientsEmptyPage';
import QuickSelectButton from '../../common/buttons/quick-select/QuickSelectButton';

import ExportToCsvButton from './buttons/ExportToCsvButton';

const { Delivered, NotSent } = MessageStatus;

function RecoverClientsManagePage(): ReactElement {
  const navigate = useNavigate();
  const { selectedBusinessId, setShowPricingTable } = useAuth();
  const { state } = useLocation();
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const openSendModal = () => setSendModalOpen(true);
  const closeSendModal = () => setSendModalOpen(false);

  const [recoverClientMessages, setRecoverClientMessages] = useState<EnrichedRecoverClientMessage[]>(state ? state.recoverClientMessages : []);
  const [flatFileUploadType, setFlatFileUploadType] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<EnrichedRecoverClientMessage[]>([]);

  const [filters, setFilters] = useState<RecoverClientsFilterType[]>([]);
  const [cursor, setCursor] = useState(0);

  const filteredRecoverClientMessages = filterRecoverClientMessages(recoverClientMessages, filters);
  const retrieveLatestMessages = async () => {
    if (selectedBusinessId) {
      const response = await getRecoverClientMessagesForBusiness(selectedBusinessId);
      setNewRecoveryMessageData(response.recoverClientMessages);
    }
  }

  const quickSelectRows = (amount: number) => {
    const quickSelectedMessages = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === NotSent).slice(0, amount);
    setSelectedRows(quickSelectedMessages);
  }

  const importButton = flatFileUploadType === FlatFileTypeKey.ClientsKey
  ? <RecipientFlatfileImport defaultDisplayText={'Upload file'} />
  : <InteractionFlatfileImport onSubmit={retrieveLatestMessages} defaultDisplayText={'Upload file'}  />;

  const resetPage = useCallback(() => setCursor(0), [setCursor]);

  const setNewRecoveryMessageData = useCallback((newRecoveryMessages: EnrichedRecoverClientMessage[]) => {
    setRecoverClientMessages(newRecoveryMessages);
    resetPage();
  }, [setRecoverClientMessages, resetPage]);

  useEffect(() => {
    if (selectedBusinessId) {
      getRecoverClientMessagesForBusiness(selectedBusinessId).then(res => {
        setNewRecoveryMessageData(res.recoverClientMessages)
      });
    }
  }, [selectedBusinessId, setNewRecoveryMessageData]);

  const fineTune = () => {
    const messagesToFineTune = selectedRows.length === 0
      ? filteredRecoverClientMessages
      : selectedRows;
    const notSentMessagesToFineTune = messagesToFineTune
      .filter(({ messageStatus }) => messageStatus === NotSent)
      .map(({ _id, recipientName, sendTo, messageStatus, sendDate, channel, message, recipientType })=>
        ({  _id, recipientName, sendTo, messageStatus, sendDate, channel, message, recipientType }));
    navigate('/message/edit', { state: { messages: notSentMessagesToFineTune, originUrl: '/reactivate-clients/manage'  }});
  };

  const sendRecoverClientMessages = async (setLoading: (loading: boolean) => void) => {
    if (selectedBusinessId) {
      const selectedMessages = selectedRows.length > 0 ? selectedRows : recoverClientMessages;
      const filteredMessages = selectedMessages.filter(selectedMessage => selectedMessage.messageStatus !== Delivered)
      const filteredMessageIds = filteredMessages.map(filteredMessage => filteredMessage._id);
      const { success, overcost } = await postRecoverClientsSendMany(filteredMessageIds, selectedBusinessId);
      if (success) {
        const updatedRecoverClientMessages = recoverClientMessages.map((recoverClientMessage) =>
          filteredMessageIds.includes(recoverClientMessage._id)
            ? ({ ...recoverClientMessage, messageStatus: Delivered })
            : recoverClientMessage
        );
        setRecoverClientMessages(updatedRecoverClientMessages);
      }
      else if (overcost) {
        setLoading(false);
        setShowPricingTable(true);
      }
    }
    closeSendModal();
  }
  const hasNoUnsentMessages = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === NotSent).length === 0;
  const sendMessagesText = selectedRows.length > 0 ? `Send selected` : 'Send all';
  const eligibleForReactivationCount = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === MessageStatus.NotSent).length;
  const reactivationSentCount = filteredRecoverClientMessages.filter(({ messageStatus }) => messageStatus === MessageStatus.Delivered).length;
  const eligibleCountText = `${eligibleForReactivationCount} clients eligible for reactivation, ${reactivationSentCount} clients sent`;
  if (recoverClientMessages.length === 0) {
    return <RecoverClientsEmptyPage flatFileUploadType={flatFileUploadType} importButton={importButton} setFlatFileUploadType={setFlatFileUploadType} />;
  }
  return (
    <article className={'recover-clients-manage-page'}>
      { sendModalOpen && <SendMessageModal cancel={closeSendModal} confirm={sendRecoverClientMessages} /> }
      <section className={'page'}>
        <header className={'recover-clients-manage-page-header'}>
          <h2 className={'title'}>{'Manage your lost clients'}</h2>
          <h3 className={'subtitle'}>{'View, edit and send messages to the clients who haven’t visited you in a while.'}</h3>
          <section className={'recover-clients-button-dashboard'}>
            <section className={'fine-tune-message-buttons'}>
              <SubtleButton additionalClassNames={['fine-tune-button']} content={'Fine tune'} onClick={fineTune}/>
              <Dropdown defaultDisplayText={'Upload file'} options={FLATFILE_UPLOAD_TYPE} select={setFlatFileUploadType} selected={flatFileUploadType} />
              {flatFileUploadType && importButton}
            </section>
            <h4 className={'input-subtitle'}>{'Fine tune your message by making notes on specific details you’d like to change in the message.'}</h4>
            <section className={'filter-bar'}>
              <PrimaryButton additionalClassNames={['send-all-button']} disabled={hasNoUnsentMessages} content={sendMessagesText} onClick={openSendModal} />
              <QuickSelectButton quickSelectRows={quickSelectRows} />
              <RecoverClientsFilter
                filteredRecoverClientMessages={filteredRecoverClientMessages}
                recoverClientMessages={recoverClientMessages}
                resetPage={resetPage}
                filters={filters}
                setFilters={setFilters}
              />
            </section>
            <section className={'eligible-clients-count'}>{eligibleCountText}</section>
            <ExportToCsvButton />
          </section>
        </header>
        <section className={'recover-clients-page-content'}>
          <RecoverClientsTable
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            recoverClientMessages={filteredRecoverClientMessages}
            setRecoverClientMessages={setRecoverClientMessages}
            cursor={cursor}
            setCursor={setCursor}
            hasMessagesToSend={!hasNoUnsentMessages}
          />
        </section>
      </section>
    </article>
  );
}

export default RecoverClientsManagePage;
