import './NurtureFlowPage.scss';

import { ReactElement, useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { LeadSource } from '../../../../types/LeadTypes';
import { LeadNurtureSettings, NurtureFlowSettings } from '../../../../types/BusinessTypes';
import { postUpdateLeadNurtureSettings } from '../../../../apis/BusinessApi';

import GenericSwitch from '../../../common/switches/GenericSwitch';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import OutlinePlusButton from '../../../common/buttons/composite/OutlinePlusButton';
import Dropdown from '../../../common/dropdowns/Dropdown';

import { LeadSourceOptions, EmptyNurtureStep } from '../LeadsConstants';

import FollowUpMessage from '../common/FollowUpMessage';

interface NurtureFlowPageProps {
  goBack: () => void,
  nurtureSettings: LeadNurtureSettings,
  setNurtureSettings: (settings: LeadNurtureSettings) => void,
}

const MAX_AUTO_FOLLOW_UPS = 3;

const defaultLeadSourceOption = LeadSourceOptions[0].value;

function NurtureFlowPage({ goBack, nurtureSettings, setNurtureSettings } : NurtureFlowPageProps): ReactElement<NurtureFlowPageProps> {
  const { selectedBusinessId, setSelectedBusiness, business } = useAuth();
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState<LeadSource>(defaultLeadSourceOption);
  const { nurtureFlows } = nurtureSettings;
  const currentNurtureFlow = nurtureSettings.nurtureFlows[source];
  const { stopNurtureIfResponse, steps } = currentNurtureFlow;
  const labelText = <span className={stopNurtureIfResponse ? 'on-text' : 'off-text'}>{stopNurtureIfResponse ? 'On' : 'Off'}</span>;
  const saveNurtureSettings = async () => {
    setLoading(true);
    if (selectedBusinessId && business) {
      await postUpdateLeadNurtureSettings(selectedBusinessId, nurtureSettings);
      setSelectedBusiness({ ...business, leadNurtureSettings: nurtureSettings });
    }
    setLoading(false);
    goBack();
  }
  const setNurtureFlow = (flow: NurtureFlowSettings) => setNurtureSettings({ ...nurtureSettings, nurtureFlows: { ...nurtureFlows, [source]: flow } });
  const setStopNurtureIfResponse = (checked: boolean) => setNurtureFlow({ ...nurtureFlows[source], stopNurtureIfResponse: checked });
  const nurtureSteps = steps.map(({ daysToWait, message }, index) => {
    const setNurtureMessage = (text: string) => {
      const newSteps = [...steps];
      newSteps[index] = { ...newSteps[index], message: text };
      setNurtureFlow({ ...nurtureFlows[source], steps: newSteps });
    }
    const setDaysToWait = (days: number) => {
      const newSteps = [...steps];
      newSteps[index] = { ...newSteps[index], daysToWait: days };
      setNurtureFlow({ ...nurtureFlows[source], steps: newSteps });
    }
    const deleteMessage = () => {
      const newSteps = [...steps];
      setNurtureFlow({ ...nurtureFlows[source], steps: newSteps.filter((_, i) => i !== index) });
    }
    return (
      <FollowUpMessage
        key={`follow-up-${index}`}
        daysToWait={daysToWait}
        text={message}
        setText={setNurtureMessage}
        setDaysToWait={setDaysToWait}
        indexNumber={index}
        deleteMessage={deleteMessage}
      />
    )
  });
  const addStep = () => setNurtureFlow({ ...nurtureFlows[source], steps: [...nurtureFlows[source].steps, EmptyNurtureStep] });
  return (
    <article className={'nurture-flow-page'}>
      <section className={'page'}>
        <section className={'nurture-flow'}>
          <header className={'header'}>
            <h2 className={'title'}>{'Nurture flows'}</h2>
            <h3 className={'subtitle'}>{'Set up automatic SMS nurture sequences for each source your leads come in from'}</h3>
          </header>
        </section>
        <section className={'page-content'}>
          <section className={'page-section'}>
            <section className={'section-header'}>
              <h4 className={'section-title'}>{'Stop nurture once lead replies'}</h4>
              <GenericSwitch label={labelText} checked={stopNurtureIfResponse} setChecked={setStopNurtureIfResponse} />
            </section>
            <Dropdown options={LeadSourceOptions} selected={source} select={setSource} />
            <section className={'follow-up-messages'}>
              {nurtureSteps}
              { steps.length < MAX_AUTO_FOLLOW_UPS && <OutlinePlusButton content={'Add follow up message'} onClick={addStep} /> }
            </section>
            <section className={'buttons'}>
              <OutlineButton content={'Back'} onClick={goBack} />
              <PrimaryButton content={'Save'} onClick={saveNurtureSettings} loading={loading} />
            </section>
          </section>
        </section>
      </section>
    </article>
  );
}

export default NurtureFlowPage;