import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import useAuth from '../../../../context/AuthContext';

import { DEFAULT_COMMERCIAL_OUTREACH_SETTINGS, LinkedInUser } from '../CommercialOutreachConstants';
import { CommercialOutreachType } from '../../../../types/BusinessTypes';
import { getLinkedInUsers } from '../../../../apis/CommercialOutreachApi';

interface BusinessLinkedInUsersData {
  linkedInUsers: LinkedInUser[],
  setLinkedInUsers: (linkedInUsers: LinkedInUser[]) => void,
  loading: boolean,
  cursor: number,
  setCursor: Dispatch<SetStateAction<number>>,
};

export function useBusinessLinkedInUsers(businessId: string | null): BusinessLinkedInUsersData {
  const [cursor, setCursor] = useState(0);
  const [linkedInUsers, setLinkedInUsers] = useState<LinkedInUser[]| null>(null);
  
  useEffect(() => {
    if (businessId) {
      setCursor(0);
      setLinkedInUsers(null);
      getLinkedInUsers(businessId).then(({ linkedInUsers }) => setLinkedInUsers(linkedInUsers));
    }
  }, [businessId]);

  return {
    linkedInUsers: linkedInUsers || [],
    setLinkedInUsers,
    loading: linkedInUsers === null,
    cursor,
    setCursor,
  };
}

interface BusinessCommercialOutreachSettingsData {
  commercialOutreachSettings: CommercialOutreachType | null,
  setCommercialOutreachSettings: (commercialOutreachSettings: CommercialOutreachType) => void,
  loading: boolean,
};

export function useBusinessCommercialOutreachSettings(businessId: string | null): BusinessCommercialOutreachSettingsData {
  const [commercialOutreachSettings, setCommercialOutreachSettings] = useState<CommercialOutreachType | null>(null);
  const { business } = useAuth();
  
  useEffect(() => {
    if (businessId) {
      setCommercialOutreachSettings(null);
      const outreachSettings = business ? business.commercialOutreachSettings : DEFAULT_COMMERCIAL_OUTREACH_SETTINGS;
      setCommercialOutreachSettings(outreachSettings);
    }
  }, [businessId, business]);

  return {
    commercialOutreachSettings: commercialOutreachSettings,
    setCommercialOutreachSettings,
    loading: commercialOutreachSettings === null,
  };
}

