import { Business } from './BusinessTypes';
import { FilterType } from '../components/common/filters/types/FilterTypes';
import { OptionType } from '../components/common/dropdowns/DropdownConstants';

const { DATE, NUMBER } = FilterType;

export enum EmailForwardingPreferences {
  ForwardAll = 'Forward All',
  OnlyRelevant = 'Only Relevant',
  None = 'None',
}

const { ForwardAll, OnlyRelevant, None } = EmailForwardingPreferences;

export const EMAIL_FORWARDING_OPTIONS = [
  { displayText: 'Forward all SMS replies to Email', value: ForwardAll },
  { displayText: 'Forward relevant SMS replies only', value: OnlyRelevant },
  { displayText: 'Do not forward replies to Email', value: None },
];

export const DEFAULT_EMAIL_FORWARDING_SETTINGS = {
  emailToSmsPreference: None,
  mobileNumber: '',
  emailForwardingList: []
}

export interface EmailForwardingSettings {
  emailToSmsPreference: EmailForwardingPreferences,
  mobileNumber: string,
  emailForwardingList: string[],
}

export const DefaultEmailForwardingSettings: EmailForwardingSettings = {
  emailToSmsPreference: EmailForwardingPreferences.None,
  mobileNumber: '',
  emailForwardingList: [],
}

export enum ReactivateLostClientsConditionAttributeText {
  LastInteractionDateText = 'Last Interaction Date',
  LastAppointmentDateText = 'Last Appointment Date',
  LastTransactionDateText = 'Last Transaction Date',
  LastMessageReceivedDateText = 'Last Received Message Date',
  LastMessageSentDateText = 'Last Sent Message Date',
  LifetimeValueText = 'Total Spend'
};

export enum ReactivateLostClientsConditionAttributeKey {
  LastInteractionDateKey = 'lastInteractionDate',
  LastAppointmentDateKey = 'lastAppointmentDate',
  LastTransactionDateKey = 'lastTransactionDate',
  LastMessageReceivedDateKey = 'lastMessageReceivedDate',
  LastMessageSentDateKey = 'lastMessageSentDate',
  LifetimeValueKey = 'lifetimeValue'
}

export enum NumberConditionText {
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
  IsGreaterThan = 'Is greater than',
  IsLessThan = 'Is less than',
  IsEqual = 'Is equal to',
  IsNotEqual = 'Not equal to',
};

export enum DateConditionText {
  IsEmpty = 'Is empty',
  IsNotEmpty = 'Is not empty',
  IsGreaterThan = 'Is after',
  IsLessThan = 'Is before',
  IsEqual = 'Is equal to',
  IsNotEqual = 'Not equal to',
  IsOneOf = 'Is One Of',
};

export enum DateConditionKey {
  IsEmptyKey = 'isEmpty',
  IsNotEmptyKey = 'isNotEmpty',
  IsGreaterThanKey = 'isGreaterThan',
  IsLessThanKey = 'isLessThan',
  IsEqualKey = 'isEqual',
  IsNotEqualKey = 'isNotEqual',
  IsOneOfKey = 'isOneOf',
};

export const REACTIVATE_CLIENT_CONDITIONS_ATTRIBUTES = [
  { displayText: ReactivateLostClientsConditionAttributeText.LastInteractionDateText, value: ReactivateLostClientsConditionAttributeKey.LastInteractionDateKey },
  { displayText: ReactivateLostClientsConditionAttributeText.LastAppointmentDateText, value: ReactivateLostClientsConditionAttributeKey.LastAppointmentDateKey },
  { displayText: ReactivateLostClientsConditionAttributeText.LastTransactionDateText, value: ReactivateLostClientsConditionAttributeKey.LastTransactionDateKey },
  { displayText: ReactivateLostClientsConditionAttributeText.LastMessageReceivedDateText, value: ReactivateLostClientsConditionAttributeKey.LastMessageReceivedDateKey },
  { displayText: ReactivateLostClientsConditionAttributeText.LastMessageSentDateText, value: ReactivateLostClientsConditionAttributeKey.LastMessageSentDateKey },
  { displayText: ReactivateLostClientsConditionAttributeText.LifetimeValueText, value: ReactivateLostClientsConditionAttributeKey.LifetimeValueKey },
];

export type FILTER_OPTIONS_MAP = Record<string, OptionType<DateConditionKey>[]>;

// Used to display the available filter options based on attribute type for a field
export const REACTIVATE_CLIENT_CONDITION_OPTIONS_MAPPING : FILTER_OPTIONS_MAP = {
  [DATE]: [
    { displayText: DateConditionText.IsEqual, value: DateConditionKey.IsEqualKey },
    { displayText: DateConditionText.IsNotEqual, value: DateConditionKey.IsNotEqualKey },
    { displayText: DateConditionText.IsGreaterThan, value: DateConditionKey.IsGreaterThanKey },
    { displayText: DateConditionText.IsLessThan, value: DateConditionKey.IsLessThanKey },
    { displayText: DateConditionText.IsEmpty, value: DateConditionKey.IsEmptyKey },
    { displayText: DateConditionText.IsNotEmpty, value: DateConditionKey.IsNotEmptyKey },
  ],
  [NUMBER]: [
    { displayText: NumberConditionText.IsEqual, value: DateConditionKey.IsEqualKey },
    { displayText: NumberConditionText.IsNotEqual, value: DateConditionKey.IsNotEqualKey },
    { displayText: NumberConditionText.IsGreaterThan, value: DateConditionKey.IsGreaterThanKey },
    { displayText: NumberConditionText.IsLessThan, value: DateConditionKey.IsLessThanKey },
    { displayText: NumberConditionText.IsEmpty, value: DateConditionKey.IsEmptyKey },
    { displayText: NumberConditionText.IsNotEmpty, value: DateConditionKey.IsNotEmptyKey },
  ],
};

export interface ReactivateLostClientConditions {
  attribute: ReactivateLostClientsConditionAttributeKey | null,
  condition: DateConditionKey | null,
  input: number | string,
}

export interface User extends UserDetails {
  businesses: Business[],
  stripeCustomerUrl: string | null,
  stripeSubscriptionId: string | null,
  stripeSubscriptionEndDate: number | null,
  creationDate: number,
  lastNewMessageReminderTime: number | null,
}

export interface UserDetails {
  firstName: string,
  lastName: string,
  emailAddress: string,
  mobileNumber: string,
}