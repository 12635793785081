import { useState, createContext, useContext, ReactNode } from 'react';
import { removeCookie, setCookie } from 'typescript-cookie'
import { useLocation, Navigate } from 'react-router-dom';

import { Business } from '../types/BusinessTypes';
import { User } from '../types/UserTypes';
import { useLocalStorage, LocalStorageKey } from '../hooks/LocalStorageHooks';

import OutOfCreditsModal from '../components/case-studies/account/modals/OutOfCreditsModal';

const { SelectedBusinessId } = LocalStorageKey;

const EmptyAuth = {
  authed: false,
  user: null,
  setAuthed: () => {},
  setUser: () => {},
  login: () => {},
  logout: () => Promise.resolve(),
  business: undefined,
  selectedBusinessId: null,
  selectBusinessId: () => {},
  setSelectedBusiness: () => {},
  showPricingTable: false,
  setShowPricingTable: () => {},
}

interface AuthProps {
  authed: boolean,
  user: User | null,
  setAuthed: (authed: boolean) => void,
  setUser: (user: User | null) => void,
  business: Business | undefined,
  login: (user: User, token: string) => void,
  logout: () => Promise<void>,
  selectBusinessId: (id: string) => void,
  selectedBusinessId: string | null,
  setSelectedBusiness: (business: Business) => void,
  showPricingTable: boolean,
  setShowPricingTable: (show: boolean) => void,
}

const AuthContext = createContext<AuthProps>(EmptyAuth);

function useAuth(): AuthProps {
  const [authed, setAuthed] = useState(false);
  const [showPricingTable, setShowPricingTable] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [selectedBusinessId, selectBusinessId, clearBusinessId] = useLocalStorage<string | null>(SelectedBusinessId, null);
  const business = user?.businesses.find(({ _id }) => _id === selectedBusinessId);
  const setSelectedBusiness = (business: Business | undefined) => {
    if (!user || !business) {
      return;
    }
    const businesses = [...user.businesses];
    const businessIndex = businesses.findIndex(({ _id }) => _id === business._id);
    businesses[businessIndex] = business;
    setUser({ ...user, businesses });
  }
  return {
    authed,
    user,
    setAuthed,
    setUser,
    selectedBusinessId,
    selectBusinessId,
    setSelectedBusiness,
    showPricingTable,
    setShowPricingTable,
    business,
    login: (user: User, token: string) => {
      setCookie('token', token);
      setUser(user);
      selectBusinessId(user?.businesses[0]._id)
      setAuthed(true);
    },
    logout: async () => {
      return new Promise(() => {
        removeCookie('token');
        setAuthed(false);
        setUser(null);
        clearBusinessId()
      });
    }
  };
};

/** Provider for authorization object (includes user data). */
export const AuthProvider = ({ children } : { children: ReactNode }) => {
  const value = useAuth();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = () => {
  return useContext(AuthContext);
};

/** Consumer for authorization object. */
export default AuthConsumer;

export interface ProtectedRouteProps {
  outlet: JSX.Element,
};

/** React wrapper to force the child component to require a certain level of authorization. */
export const RequireAuth = ({ outlet } : ProtectedRouteProps) => {
  const { authed, showPricingTable, setShowPricingTable } = AuthConsumer();
  const { pathname } = useLocation();
  if (authed) {
    return (
      <>
        { showPricingTable && <OutOfCreditsModal closeModal={() => setShowPricingTable(false)} /> }
        {outlet}
      </>
      
    );
  }
  return <Navigate to={'/login'} replace={true} state={{ path: pathname }} />;
};

/** React wrapper to force the child component to NOT require a certain level of authorization i.e. pages on available to logged out users. */
export const RequireNoAuth = ({ outlet } : ProtectedRouteProps) => {
  const { authed } = AuthConsumer();
  const { pathname, state } = useLocation();
  const path = state && state.path ? state.path : '/';
  if (authed) {
    return <Navigate to={path} replace={true} state={{ path: pathname }} />;
  }
  return outlet;
};
