import { useEffect } from 'react';
import { getCookie } from 'typescript-cookie';

import useAuth from '../context/AuthContext';
import { getUser } from '../apis/UserApi';
import { DefaultEmailForwardingSettings, EmailForwardingSettings, ReactivateLostClientConditions } from '../types/UserTypes';

/**
 * When loading the app for the first time, check in cookies if the user has a valid token.
 * If so, retrieve their details and mark them as logged in.
 * @param {*} firstLoad 
 * @param {*} setFirstLoad 
 */
export const useInitialUserFetch = (firstLoad: boolean, setFirstLoad: (firstLoad: boolean) => void) => {
  const { authed, setAuthed, setUser, selectedBusinessId, selectBusinessId } = useAuth();
  useEffect(() => {
    const token = getCookie('token');
    if (!authed && token) {
      const populateUser = async () => {
        try {
          const { user, success } = await getUser();
          if (success && user !== null) {
            setUser(user);
            if (selectedBusinessId === null || !user?.businesses.find(({ _id }) => _id === selectedBusinessId)) {
              selectBusinessId(user?.businesses[0]._id);
            }
            setAuthed(true);
            setFirstLoad(true);
          }
          else {
            setFirstLoad(true);
          }
        }
        catch (err) {
          console.error(err);
          setFirstLoad(true);
        }
      }
      populateUser();
    }
    else {
      setFirstLoad(true);
    }
  }, [firstLoad, setFirstLoad, authed, setAuthed, setUser, selectedBusinessId, selectBusinessId]);
}

/**
 * TODO: Fetches reactivate client settings when selectedBusinessId changes.
 * @returns 
 */
export const useReactivateClientSettingsFetch = (
    setEmailForwardingSettings: React.Dispatch<React.SetStateAction<EmailForwardingSettings>>,
    setReactivationConditions: React.Dispatch<React.SetStateAction<ReactivateLostClientConditions[]>>
  ) => {
  const { selectedBusinessId, user } = useAuth();
  useEffect(() => {
    const businesses = user?.businesses || [];
    const foundBusiness = businesses.find(({ _id }) => _id === selectedBusinessId) || undefined;
    setEmailForwardingSettings(foundBusiness?.emailForwardingSettings || DefaultEmailForwardingSettings);
    setReactivationConditions(foundBusiness?.reactivateLostClientConditions || []);
  }, [selectedBusinessId, user, setEmailForwardingSettings, setReactivationConditions]);
};
