import './LivingNearbySettingsPage.scss';

import { ReactElement, useCallback, useState, useEffect, useMemo } from 'react';

import { usePlacesWidget } from 'react-google-autocomplete';

import useAuth from '../../../../context/AuthContext';
import { Business, LivingNearbySettings } from '../../../../types/BusinessTypes';
import { postUpdateBusinessLivingNearbySettings } from '../../../../apis/BusinessApi';

import RemovableItem from '../../../common/rows/RemovableItem';
import PrimaryInput from '../../../common/inputs/PrimaryInput';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBN79DWVz6nD-htQLf37KfKv3Bnd4cbkxk';

interface GoogleAddressObject {
  formatted_address: string,
}

function LivingNearbySettingsPage(): ReactElement {
  const { setSelectedBusiness, business, selectedBusinessId } = useAuth();
  const [currentPlace, setCurrentPlace] = useState('');
  const [placeInput, setPlaceInput] = useState('');
  const places = useMemo(() => business?.livingNearbySettings?.suburbs || [], [business]);
  const setPlaces = useCallback(async (suburbs: string[]) => {
    const livingNearbySettings: LivingNearbySettings = { suburbs };
    setSelectedBusiness({ ...business, livingNearbySettings } as Business);
    if (selectedBusinessId) {
      await postUpdateBusinessLivingNearbySettings(selectedBusinessId, livingNearbySettings);
    }
  }, [setSelectedBusiness, business, selectedBusinessId])
  const onPlaceSelected = (place: GoogleAddressObject) => {
    const selectedPlace = place.formatted_address;
    setPlaceInput(selectedPlace);
    setCurrentPlace(selectedPlace);
  };

  // Necessary to allow for a re-render when a place is selected from the autocomplete dropdown.
  useEffect(() => {
    if (currentPlace) {
      if (!places.includes(currentPlace) && places.length < 5) {
        const suburbs = [...places, currentPlace];
        setPlaces(suburbs);
      }
      setPlaceInput('');
      setCurrentPlace('');
    }
  }, [currentPlace, places, setPlaces]);
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected,
    options: {
      types: ['(regions)'],
    },
  });
  const placesList = places.map((place, index) => {
    const deleteIndex = () => setPlaces(places.filter((_, i) => i !== index));
    return (
      <RemovableItem additionalClassNames={['place']} removeItem={deleteIndex} key={place}>
        <span className={'index'}>{index + 1}</span>
        <span className={'name'}>{place}</span>
      </RemovableItem>
    );
  });
  console.log(places);
  return (
    <article className={'living-nearby-settings-page'}>
      <section className={'page'}>
        <header className={'living-nearby-settings-page-header'}>
          <h2 className={'title'}>{'Settings'}</h2>
          <h3 className={'subtitle'}>{'View and edit your settings'}</h3>
        </header>
        <section className={'page-content'}>
          <section className={'section-container'}>
            <h4 className={'input-title'}>{'What area would you like to target?'}</h4>
            <h5 className={'input-subtitle'}>{'Add up to 5 suburbs for this outreach'}</h5>
            <section className={'input-container'}>
              <label className={'input-label'} htmlFor={'number-of-clients-input'}>{'Suburb'}</label>
              <PrimaryInput
                inputRef={ref}
                placeholder={'Start typing suburb to search'}
                text={placeInput}
                setText={setPlaceInput}
              />
            </section>
            <section className={'places-list'}>
              {placesList}
            </section>
          </section>
        </section>
      </section>
    </article>
  );
}

export default LivingNearbySettingsPage;