import './LeadsSettingsPage.scss';

import { ReactElement, useEffect, useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { postUpdateLeadEmailForwardingSettings } from '../../../../apis/BusinessApi';
import { EmailForwardingSettings, DefaultEmailForwardingSettings } from '../../../../types/UserTypes';
import { LeadNurtureSettings } from '../../../../types/BusinessTypes';
import { DefaultLeadNurtureSettings } from '../LeadsConstants';

import { OptionType } from '../../../common/dropdowns/DropdownConstants';

import OutlineButton from '../../../common/buttons/OutlineButton';

import NurtureFlowPage from './NurtureFlowPage'
import EmailForwarding from '../../common/EmailForwarding';

function LeadsSettingsPage(): ReactElement {
  const { business, selectedBusinessId, setSelectedBusiness } = useAuth();
  const automaticNurtureSettings = business?.leadNurtureSettings || DefaultLeadNurtureSettings;
  const initialEmailForwardingSettings = business?.leadEmailForwardingSettings || DefaultEmailForwardingSettings;
  const [showingNurture, setShowingNurture] = useState(false);
  const [emailForwardingSettings, setEmailForwardingSettings] = useState<EmailForwardingSettings>(initialEmailForwardingSettings);
  const [nurtureSettings, setNurtureSettings] = useState<LeadNurtureSettings>(automaticNurtureSettings);

  useEffect(() => {
    setEmailForwardingSettings(initialEmailForwardingSettings);
    setNurtureSettings(automaticNurtureSettings);
  }, [business, initialEmailForwardingSettings, automaticNurtureSettings]);

  if (showingNurture) {
    return (
      <NurtureFlowPage
        goBack={() => setShowingNurture(false)}
        nurtureSettings={nurtureSettings}
        setNurtureSettings={setNurtureSettings}
      />
    );
  }
  const viewNurture = () => setShowingNurture(true);
  const setAndPostSettings = async (emailForwardingSettings: EmailForwardingSettings) => {
    console.log({ business, selectedBusinessId, emailForwardingSettings });
    setEmailForwardingSettings(emailForwardingSettings);
    if (business && selectedBusinessId) {
      await postUpdateLeadEmailForwardingSettings(selectedBusinessId, emailForwardingSettings);
      setSelectedBusiness({ ...business, leadEmailForwardingSettings: emailForwardingSettings });
    }
  }
  const phoneNumberOptions: OptionType<string>[] = [];
  return (
    <article className={'leads-settings-page'}>
      <section className={'page'}>
        <section className={'leads-settings'}>
          <header className={'header'}>
            <h2 className={'title'}>{'Settings'}</h2>
            <h3 className={'subtitle'}>{'View and edit your settings for your lead'}</h3>
          </header>
        </section>
        <section className={'page-content'}>
          <section className={'page-section'}>
            <section className={'section-header'}>
              <h4 className={'section-title'}>{'Nurture flows'}</h4>
            </section>
            <section className={'section-subtitle'}>
              {'Set up your nurture message sequences for new leads'}
            </section>
            <OutlineButton content={'Manage nurture flows'} onClick={viewNurture} />
          </section>
          <section className={'page-section'}>
            <EmailForwarding
              emailForwardingSettings={emailForwardingSettings}
              setEmailForwardingSettings={setAndPostSettings}
              phoneNumbers={phoneNumberOptions.map(({ value }) => value)}
              subtitle={'Receive and send SMS to your leads directly from your email'}
            />
          </section>
        </section>
      </section>
    </article>
  );
}

export default LeadsSettingsPage;