import './UpgradePlanModal.scss';

import { ReactElement } from 'react';

import Modal from '../../../common/modals/Modal';
import OutlineButton from '../../../common/buttons/OutlineButton';

import StripePricingTable from '../tables/StripePricingTable';

interface UpgradePlanModalProps {
  additionalClassNames?: string[],
  closeModal: () => void,
}

function UpgradePlanModal({ additionalClassNames = [], closeModal } : Readonly<UpgradePlanModalProps>): ReactElement<UpgradePlanModalProps> {
  return (
    <Modal additionalClassNames={['upgrade-plan-modal', ...additionalClassNames]}>
      <section className={'upgrade-plan-modal-content'}>
        <header className={'modal-header'}>
          <h4 className={'modal-title'}>{'Upgrade your plan'}</h4>
          <h5 className={'modal-subtitle'}>{'Generate more revenue with more credits'}</h5>
        </header>
        <StripePricingTable />
        <section className={'close-button-container'}>
          <OutlineButton content={'Cancel'} onClick={closeModal} />
        </section>
      </section>
    </Modal>
  )
}

export default UpgradePlanModal;