import './CommercialOutreachEmptyPage.scss';

import { ReactElement, useState } from 'react';

import OutlinePlusButton from '../../../common/buttons/composite/OutlinePlusButton';
import { postRequestOutreachToSlack } from '../../../../apis/CommercialOutreachApi';
import useAuth from '../../../../context/AuthContext';

function CommercialOutreachEmptyPage(): ReactElement {
  const { selectedBusinessId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const logOutreachRequestToSlack = async () => {
    if (selectedBusinessId) {
      setLoading(true);
      await postRequestOutreachToSlack(selectedBusinessId);
      setRequestSent(true);
      setLoading(false);
    }
  }

  const buttonText = requestSent ? 'Request sent!' : 'Request outreach';
  return (
    <section className={'empty-commercial-outreach'}>
      <h3 className={'subtitle'}>{'No people found yet, check back later or begin a new search'}</h3>
      <OutlinePlusButton content={buttonText} onClick={logOutreachRequestToSlack} loading={loading} />
    </section>
  )
}

export default CommercialOutreachEmptyPage;