
import './AccountDetailsPage.scss';

import { ReactElement, useEffect, useState } from 'react';

import { postUpdateUserDetails } from '../../../../apis/UserApi';
import useAuth from '../../../../context/AuthContext';

import PrimaryInput from '../../../common/inputs/PrimaryInput';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { Business } from '../../../../types/BusinessTypes';
import { User } from '../../../../types/UserTypes';

interface AccountDetails {
  firstName: string,
  lastName: string,
  emailAddress: string,
  mobileNumber: string,
  businessName: string,
  businessAddress: string,
  businessType: string,
  website: string,
  businessContactNumber: string,
}

function AccountDetailsPage(): ReactElement {
  const { user, business, selectedBusinessId, setSelectedBusiness, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const initialAccountDetails = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    emailAddress: user?.emailAddress || '',
    mobileNumber: user?.mobileNumber || '',
    businessName: business?.businessName || '',
    businessAddress: business?.businessAddress || '',
    businessType: business?.businessType || '',
    website: business?.website || '',
    businessContactNumber: business?.businessContactNumber || ''
  };
  const [accountDetails, setAccountDetails] = useState<AccountDetails>(initialAccountDetails);
  const {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    businessName,
    businessAddress,
    businessType,
    website,
    businessContactNumber,
  } = accountDetails;
  const setFirstName = (value: string) => setAccountDetails({ ...accountDetails, firstName: value });
  const setLastName = (value: string) => setAccountDetails({ ...accountDetails, lastName: value });
  const setEmailAddress = (value: string) => setAccountDetails({ ...accountDetails, emailAddress: value });
  const setMobileNumber = (value: string) => setAccountDetails({ ...accountDetails, mobileNumber: value });
  const setBusinessName = (value: string) => setAccountDetails({ ...accountDetails, businessName: value });
  const setBusinessAddress = (value: string) => setAccountDetails({ ...accountDetails, businessAddress: value });
  const setBusinessType = (value: string) => setAccountDetails({ ...accountDetails, businessType: value });
  const setBusinessWebsite = (value: string) => setAccountDetails({ ...accountDetails, website: value });
  const setBusinessContactNumber = (value: string) => setAccountDetails({ ...accountDetails, businessContactNumber: value });
  const cancel = () => setAccountDetails(initialAccountDetails);

  const save = async () => {
    if (!selectedBusinessId) {
      return;
    }
    setLoading(true);
    const userDetails = {
      firstName,
      lastName,
      emailAddress,
      mobileNumber
    };
    const businessDetails = {
      businessName,
      businessAddress,
      businessType,
      website,
      businessContactNumber,
    }
    const { success } = await postUpdateUserDetails(userDetails, selectedBusinessId, businessDetails);
    if (success) {

      // Note we have to update the user first otherwise the old business with be overwritten
      setUser({ ...user, ...userDetails } as User);
      setSelectedBusiness({ ...business, ...businessDetails } as Business);
    }
    setLoading(false);
  }

  const hasChanged = JSON.stringify(initialAccountDetails) !== JSON.stringify(accountDetails);

  useEffect(() => {
    const {
      businessAddress = '',
      businessType = '',
      website = '',
      businessContactNumber = '',
    } = business || {};
    setAccountDetails(prevAccountDetails => ({
      ...prevAccountDetails,
      businessAddress,
      businessType,
      website,
      businessContactNumber
    }));
  }, [business]);
  return (
    <article className={'account-details-page'}>
      <section className={'page'}>
        <header className={'account-details-header'}>
          <h2 className={'title'}>{'Account details'}</h2>
          <h3 className={'subtitle'}>{'View and edit your account details'}</h3>
        </header>
        <section className={'account-details'}>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'first-name'}>{'First name'}</label>
            <PrimaryInput text={firstName} setText={setFirstName} placeholder={'First name'} id={'first-name'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'last-name'}>{'Last name'}</label>
            <PrimaryInput text={lastName} setText={setLastName} placeholder={'Last name'} id={'last-name'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'email-address'}>{'Email address'}</label>
            <PrimaryInput text={emailAddress} setText={setEmailAddress} placeholder={'Email address'} id={'email-address'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'mobile-number'}>{'Mobile number'}</label>
            <PrimaryInput text={mobileNumber} setText={setMobileNumber} placeholder={'Mobile number'} id={'mobile-number'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'business-name'}>{'Business name'}</label>
            <PrimaryInput text={businessName} setText={setBusinessName} placeholder={'Business name'} id={'business-name'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'business-address'}>{'Business address'}</label>
            <PrimaryInput text={businessAddress} setText={setBusinessAddress} placeholder={'Business address'} id={'business-address'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'business-type'}>{'Business type'}</label>
            <PrimaryInput text={businessType} setText={setBusinessType} placeholder={'Business type'} id={'business-type'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'website'}>{'Business website'}</label>
            <PrimaryInput text={website} setText={setBusinessWebsite} placeholder={'Business website'} id={'website'} />
          </section>
          <section className={'account-details-section'}>
            <label className={'section-label'} htmlFor={'business-contact-number'}>{'Business contact number'}</label>
            <PrimaryInput text={businessContactNumber} setText={setBusinessContactNumber} placeholder={'Business contact number'} id={'business-contact-number'} />
          </section>
        </section>
        { hasChanged && (
            <section className={'buttons'}>
              <OutlineButton content={'Cancel'} onClick={cancel} />
              <PrimaryButton content={'Save changes'} onClick={save} loading={loading} />
            </section>
        )}
      </section>
    </article>
  );
}

export default AccountDetailsPage;