import { getData, postData } from './Api';
import { EnrichedRecoverClientMessage } from '../components/case-studies/reactivate-clients/RecoverClientsConstants';
import { Message } from '../components/case-studies/reactivate-clients/MessageConstants';
import { OvercostResponse } from './types/ResponseTypes';

// TODO: Delete unused routes and update comments to reflect new structure i.e. request interfaces, response interfaces
// TODO: Rename to reacticate-client

const PostCaseStudyUrls = {
  RecoverClientMessagesForBusinessUrl: '/api/recover-client-message/messages',
  UpdateRecoverClientMessagesUrl: '/api/recover-client-message/saveOrUpdateMany',
  DeleteRecoverClientMessagesUrl: '/api/recover-client-message/deleteMany',
  SendRecoverClientMessagesUrl: '/api/recover-client-message/sendMany',
  RecoverClientSetupUrl: '/api/recover-client-message/setup',
};

const {
  RecoverClientMessagesForBusinessUrl,
  UpdateRecoverClientMessagesUrl,
  DeleteRecoverClientMessagesUrl,
  SendRecoverClientMessagesUrl,
  RecoverClientSetupUrl
} = PostCaseStudyUrls;

interface RecoverClientMessagesResponse {
  recoverClientMessages: EnrichedRecoverClientMessage[]
};

// Recover Lost Client - Client Type Setup Request
interface RecoverClientMessagesSetupRequest {
  businessId: string,
  requestDetails: string,
};

interface RecoverClientMessagesRequest {
  messages: Message[]
  businessId: string,
};

interface RecoverClientMessagesDeleteManyRequest {
  recoverClientMessageIds: string[],
}

interface RecoverClientMessagesDeleteManyResponse {
  success: boolean,
}

interface RecoverClientMessagesSendManyRequest {
  recoverClientMessageIds: string[],
  businessId: string
}

/**
 * Fetches all recover client messages for a particular business id.
 */
export async function getRecoverClientMessagesForBusiness(businessId: string): Promise<RecoverClientMessagesResponse> {
  return getData<RecoverClientMessagesResponse>(`${RecoverClientMessagesForBusinessUrl}/${businessId}`);
}

/**
 * Saves lost client messages and returns the updated ones.
 */
export async function postRecoverClientMessagesUpdate(messages: Message[], businessId: string): Promise<OvercostResponse> {
  return postData<RecoverClientMessagesRequest, OvercostResponse>(
    UpdateRecoverClientMessagesUrl, { messages, businessId }
  );
}

/**
 * Saves the Lost Client Setup Input.
 */
export async function postRecoverClientsSetup(businessId: string, requestDetails: string): Promise<boolean> {
  return postData<RecoverClientMessagesSetupRequest, boolean>(
    RecoverClientSetupUrl, { businessId, requestDetails }
  );
}

/**
 * Sets the deletion date for the selected recover client messages.
 */
export async function postRecoverClientsDeleteMany(recoverClientMessageIds: string[]): Promise<RecoverClientMessagesDeleteManyResponse> {
  return postData<RecoverClientMessagesDeleteManyRequest, RecoverClientMessagesDeleteManyResponse>(
    DeleteRecoverClientMessagesUrl, { recoverClientMessageIds }
  );
}

/**
 * Sets the deletion date for the selected recover client messages.
 */
export async function postRecoverClientsSendMany(recoverClientMessageIds: string[], businessId: string): Promise<OvercostResponse> {
  return postData<RecoverClientMessagesSendManyRequest, OvercostResponse>(
    SendRecoverClientMessagesUrl, { recoverClientMessageIds, businessId }
  );
}