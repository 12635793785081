import './CommercialOutreachSettingsPage.scss';

import { ReactElement } from 'react';

import useAuth from '../../../../context/AuthContext';
import { useBusinessCommercialOutreachSettings } from '../hooks/CommercialOutreachHooks';
import { postUpdateBusinessCommercialOutreachLinkedInSettings, postUpdateBusinessCommercialOutreachSettings } from '../../../../apis/BusinessApi';

import LoadingRing from '../../../common/loading/LoadingRing';
import AddressSearchBar from '../../common/AddressSearchBar';
import LinkedInSettings from '../settings/LinkedInSettings';
import { ConnectionStatus, LinkedInCredentials } from '../../../../types/BusinessTypes';

function CommercialOutreachSettingsPage(): ReactElement {
  const { selectedBusinessId, setSelectedBusiness, business } = useAuth();
  const { commercialOutreachSettings, setCommercialOutreachSettings, loading } = useBusinessCommercialOutreachSettings(selectedBusinessId);
  const suburbs = commercialOutreachSettings ? commercialOutreachSettings.suburbs : [];

  const updateAuthenticationDetails = async (linkedInCredentials: LinkedInCredentials, connectionStatus: ConnectionStatus) => {
    if (commercialOutreachSettings === null) {
      return;
    }
    const updatedCommercialOutreachSettings = { ...commercialOutreachSettings, linkedInCredentials, connectionStatus };
    setCommercialOutreachSettings(updatedCommercialOutreachSettings);
    if (selectedBusinessId && business) {
      await postUpdateBusinessCommercialOutreachLinkedInSettings(selectedBusinessId, updatedCommercialOutreachSettings);
      setSelectedBusiness({ ...business, commercialOutreachSettings: updatedCommercialOutreachSettings });
    }
  }

  const updateSuburbSettings = async (updatedSuburbs: string[]) => {
    if (commercialOutreachSettings === null) {
      return;
    }
    const updatedCommercialOutreachSettings = { ...commercialOutreachSettings, suburbs: updatedSuburbs };
    setCommercialOutreachSettings(updatedCommercialOutreachSettings);
    if (selectedBusinessId && business) {
      await postUpdateBusinessCommercialOutreachSettings(selectedBusinessId, updatedCommercialOutreachSettings);
      setSelectedBusiness({ ...business, commercialOutreachSettings: updatedCommercialOutreachSettings });
    }
  }

  return (
    <article className={'reactivate-clients-settings-page'}>
      <section className={'page'}>
        <header className={'reactivate-clients-settings-page-header'}>
          <h2 className={'title'}>{'Settings'}</h2>
          <h3 className={'subtitle'}>{'View and edit your settings for finding clients working nearby'}</h3>
        </header>
        {
          loading ? <LoadingRing color={'#000000'} /> : (
            <section className={'page-content'}>
              <LinkedInSettings commercialOutreachSettings={commercialOutreachSettings} updateAuthenticationDetails={updateAuthenticationDetails} />
              <AddressSearchBar suburbs={suburbs} setSuburbs={updateSuburbSettings} />
            </section>
          )
        }
      </section>
    </article>
  );
}

export default CommercialOutreachSettingsPage;