import { ReactElement, useState } from 'react';

import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';
import Modal from '../../../common/modals/Modal';

interface ConfirmSendLettersModalProps {
  cancel: () => void,
  confirm: (setLoading: (loading: boolean) => void) => Promise<void>,
  numAddressesSelected: number,
}

function ConfirmSendLettersModal({ cancel, confirm, numAddressesSelected } : ConfirmSendLettersModalProps): ReactElement {
  const [loading, setLoading] = useState(false);
  return (
    <Modal>
      <ConfirmationModalContent
        title={'Send to all?'}
        subtitle={`Confirm you’d like to send letters to all ${numAddressesSelected} addresses.`}
        confirmText={'Confirm'}
        cancelText={'Cancel'}
        cancel={cancel}
        confirm={() => confirm(setLoading)}
        loading={loading}
      />
    </Modal>
  );
}

export default ConfirmSendLettersModal;