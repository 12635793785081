import './LinkedInConnectionStatusButton.scss';

import { ReactElement } from 'react';

import ConnectLinkedInAccountButton from './ConnectLinkedInAccountButton';
import ConnectedLinkedInButton from './ConnectedLinkedInButton';
import { ConnectionStatus } from '../../../../types/BusinessTypes';

interface LinkedInConnectionStatusButtonProps {
  connectionStatus: ConnectionStatus,
  openLoginModal: () => void,
  unlinkAccount: () => void
};

function LinkedInConnectionStatusButton({ openLoginModal, connectionStatus, unlinkAccount } : Readonly<LinkedInConnectionStatusButtonProps>): ReactElement {

  const connectionButton = connectionStatus === ConnectionStatus.NotConnected
    ? <ConnectLinkedInAccountButton openLoginModal={openLoginModal} />
    : <ConnectedLinkedInButton connectionStatus={connectionStatus} unlinkAccount={unlinkAccount} />

  return connectionButton;
}

export default LinkedInConnectionStatusButton;