import './App.scss';

import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { RequireAuth, RequireNoAuth } from './context/AuthContext';
import { useInitialUserFetch } from './hooks/DataHooks';

import LoginViewManager from './components/login/LoginViewManager';
import LoadingRingPage from './components/common/pages/LoadingRingPage';
import DashboardPage from './components/case-studies/dashboard/pages/DashboardPage';
import WorkRexPage from './components/wrappers/WorkRexPage';
import LivingNearbyAboutPage from './components/case-studies/living-nearby/pages/LivingNearbyAboutPage';
import LivingNearbyManagePage from './components/case-studies/living-nearby/pages/LivingNearbyManagePage';
import RecoverClientsAboutPage from './components/case-studies/reactivate-clients/pages/ReactivateLostClientsAboutPage';
import RecoverClientsSetupPage from './components/case-studies/reactivate-clients/RecoverClientsSetupPage';
import RecoverClientsManagePage from './components/case-studies/reactivate-clients/RecoverClientsManagePage';
import RecoverClientsMessagePage from './components/case-studies/reactivate-clients/MessagePreviewPage';
import RecoverClientsSettingsPage from './components/case-studies/reactivate-clients/RecoverClientsSettingsPage';
import UsageAndBillingPage from './components/case-studies/account/pages/UsageAndBillingPage';
import CommercialOutreachAboutPage from './components/case-studies/commercial-outreach/pages/CommercialOutreachAboutPage';
import CommercialOutreachManagePage from './components/case-studies/commercial-outreach/pages/CommercialOutreachManagePage';
import CommercialOutreachSettingsPage from './components/case-studies/commercial-outreach/pages/CommercialOutreachSettingsPage';
import CommercialOutreachConnectionMessagePage from './components/case-studies/commercial-outreach/pages/CommercialOutreachConnectionMessagePage';
import ViewLeadsPage from './components/case-studies/leads/pages/ViewLeadsPage';
import LeadsSettingsPage from './components/case-studies/leads/pages/LeadsSettingsPage';
import LivingNearbySettingsPage from './components/case-studies/living-nearby/pages/LivingNearbySettingsPage';
import AccountDetailsPage from './components/case-studies/account/pages/AccountDetailsPage';

function App() {
  const [firstLoad, setFirstLoad] = useState(false);
  useInitialUserFetch(firstLoad, setFirstLoad);
  if (!firstLoad) {
    return <LoadingRingPage color={'#000000'} />;
  }
  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<RequireAuth outlet={<WorkRexPage />} />}>
          <Route path={'home'} element={<DashboardPage />} />

          <Route path={'/account'}>
            <Route path={'billing'} element={<UsageAndBillingPage />} />
            <Route index element={<AccountDetailsPage />} />
          </Route>

          <Route path={'leads'}>
            <Route path={'settings'} element={<LeadsSettingsPage />} />
            <Route index element={<ViewLeadsPage />} />
          </Route>

          <Route path={'living-nearby'}>
            <Route index element={<LivingNearbyAboutPage />} />
            <Route path={'about'} element={<LivingNearbyAboutPage />} />
            <Route path={'manage'} element={<LivingNearbyManagePage />} />
            <Route path={'settings'} element={<LivingNearbySettingsPage />} />
          </Route>

          <Route path={'commercial-outreach'}>
            <Route path={'about'} element={<CommercialOutreachAboutPage />} />
            <Route path={'setup'} element={null} />
            <Route path={'manage'} element={<CommercialOutreachManagePage />} />
            <Route path={'settings'} element={<CommercialOutreachSettingsPage />} />
            <Route path={'settings/connection-message'} element={<CommercialOutreachConnectionMessagePage />} />
            <Route index element={<CommercialOutreachAboutPage />} />
          </Route>
          
          <Route path={'reactivate-clients'}>
            <Route path={'about'} element={<RecoverClientsAboutPage />} />
            <Route path={'setup'} element={<RecoverClientsSetupPage />} />
            <Route path={'manage'} element={<RecoverClientsManagePage />} />
            <Route path={'settings'} element={<RecoverClientsSettingsPage />} />
            <Route index element={<RecoverClientsAboutPage />} />
          </Route>

          <Route path={'message/edit'} element={<RecoverClientsMessagePage />} />
        </Route>

        <Route path={'login'} element={<RequireNoAuth outlet={<LoginViewManager />} />} />
      </Routes>
    </Router>
  );
}

export default App;
