import './CommercialOutreachTableButtons.scss';

import { ReactElement } from 'react';

import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import GhostButton from '../../../common/buttons/GhostButton'
import ShowCompletedToggleButton from '../../../common/buttons/composite/ShowCompletedToggleButton';
import { useNavigate } from 'react-router';

interface CommercialOutreachTableButtonsProps {
  sendButtonText: string,
  openConfirmModal: () => void,
  showSendAllButton: boolean,
  showCompleted: boolean,
  setShowCompleted: React.Dispatch<React.SetStateAction<boolean>>
};

function CommercialOutreachTableButtons({ sendButtonText, openConfirmModal, showSendAllButton, showCompleted, setShowCompleted } : Readonly<CommercialOutreachTableButtonsProps>): ReactElement {
  const navigate = useNavigate();
  const toggleCompleted = () => setShowCompleted(!showCompleted);

  const viewMessage = (): void => navigate('/commercial-outreach/settings/connection-message', { state: { originUrl: '/commercial-outreach/manage' }});

  return (
    <section className={'table-buttons'}>
      <OutlineButton additionalClassNames={['view-letter-button']} content={'View message'} onClick={viewMessage} />
      { showSendAllButton && <PrimaryButton additionalClassNames={['send-all-button']} content={sendButtonText} onClick={openConfirmModal} /> }
      <GhostButton additionalClassNames={['show-complete-button']} content={<ShowCompletedToggleButton showCompleted={showCompleted} />} onClick={toggleCompleted} />
    </section>
  )
}

export default CommercialOutreachTableButtons;