import { useEffect, useState } from 'react';

import { BusinessEstimatedRevenueSettings, BusinessMetrics } from '../../../../types/MetricTypes';
import { getBusinessEstimatedRevenueSettings, getBusinessMetrics } from '../../../../apis/BusinessApi';
import { DefaultLeadToClientProportion, DefaultRevenuePerClient } from '../DashboardConstants';

interface BusinessMetricsData {
  metrics: BusinessMetrics | null,
  setMetrics: (businessMetrics: BusinessMetrics | null) => void,
  loading: boolean,
};

export function useBusinessMetrics(businessId: string | null): BusinessMetricsData {
  const [metrics, setMetrics] = useState<BusinessMetrics | null>(null);

  useEffect(() => {
    if (businessId) {
      setMetrics(null);
      getBusinessMetrics(businessId)
        .then(({ handwrittenLetterMetrics, reactivateLostClientsMetrics, commercialOutreachMetrics}) => 
          setMetrics({ handwrittenLetterMetrics, reactivateLostClientsMetrics, commercialOutreachMetrics }));
    }
  }, [businessId]);
  
  return {
    metrics,
    setMetrics,
    loading: metrics === null,
  };
}

interface EstimatedRevenueSettings {
  estimatedRevenueSettings: BusinessEstimatedRevenueSettings,
  setEstimatedRevenueSettings: (estimatedRevenueSettings: BusinessEstimatedRevenueSettings) => void,
  loading: boolean,
};

export function useBusinessEstimatedRevenueSettings(businessId: string | null): EstimatedRevenueSettings {
  const [estimatedRevenueSettings, setEstimatedRevenueSettings] = useState<BusinessEstimatedRevenueSettings | null>(null);

  useEffect(() => {
    if (businessId) {
      setEstimatedRevenueSettings(null);
      getBusinessEstimatedRevenueSettings(businessId)
        .then(({ estimatedRevenueSettings }) => 
          setEstimatedRevenueSettings(estimatedRevenueSettings));
    }
  }, [businessId]);
  
  return {
    estimatedRevenueSettings: estimatedRevenueSettings
      ? estimatedRevenueSettings
      : { leadToClientProportion: parseInt(DefaultLeadToClientProportion), revenuePerClient: parseInt(DefaultRevenuePerClient) },
    setEstimatedRevenueSettings,
    loading: estimatedRevenueSettings === null,
  };
}