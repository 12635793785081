import './LivingNearbyManagePage.scss';

import { ReactElement, useState } from 'react';

import { postSendLettersToAddresses } from '../../../../apis/LettersApi';
import useAuth from '../../../../context/AuthContext';

import Filter from '../../../common/filters/Filter';
import GhostButton from '../../../common/buttons/GhostButton';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import QuickSelectButton from '../../../common/buttons/quick-select/QuickSelectButton';
import ShowCompletedToggleButton from '../../../common/buttons/composite/ShowCompletedToggleButton';
import SearchInput from '../../../common/inputs/SearchInput';
import LoadingRing from '../../../common/loading/LoadingRing';

import { Address, LetterStatus, AddressFilterAttributeMap, AddressFilterAttributeOptions } from '../HandwrittenLettersConstants';
import { FilterItem } from '../../../common/filters/types/FilterTypes';
import { filterAddresses, searchAddresses } from '../helpers/LivingNearbyFilterHelpers';
import { useBusinessAddresses } from '../hooks/LivingNearbyHooks';

import HandwrittenLettersTable from '../tables/HandwrittenLettersTable';
import ConfirmSendLettersModal from '../modals/ConfirmSendLettersModal';
import AddressFlatfileImport from '../../../../utils/flatfile/AddressFlatfileImport';

// TODO: Simplify logic in this file.

function LivingNearbyManagePage(): ReactElement {
  const { business, selectedBusinessId, setShowPricingTable } = useAuth();
  const offerUrl = business?.offerUrl;
  const letterUrl = business?.letterUrl;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(true);
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [text, setText] = useState<string>('');
  const { cursor, setCursor, setAddresses, addresses, loading }  = useBusinessAddresses(selectedBusinessId);
  const [selectedAddresses, setSelectedAddresses] = useState<Address[]>([]);

  const searchForAddresses = (searchText: string) => {
    setText(searchText);
    setCursor(0);
  };
  const toggleCompleted = () => setShowCompleted(!showCompleted);
  const setAddressesAndResetCursor = (newAddressData: Address[]) => {
    setAddresses(newAddressData);
    setCursor(0);
  };
  const closeConfirmModal = () => setConfirmModalOpen(false);
  const openConfirmModal = () => setConfirmModalOpen(true);
  const addressesFilteredByStatus = addresses.filter(({ status }) => showCompleted || status !== LetterStatus.Complete);
  const filteredAddresses = filterAddresses(addressesFilteredByStatus, filters);
  const addressesToDisplay = searchAddresses(filteredAddresses, text);
  const showSendAllButton = addressesToDisplay.filter(
    ({ shouldSend, status }) => !shouldSend && status !== LetterStatus.Complete && status !== LetterStatus.Requested).length > 0;

  const sendLetters = async (setLoading: (loading: boolean) => void) => {
    setLoading(true);
    const addressIds = addressesToDisplay
      .filter((address) => selectedAddresses.length > 0 ? selectedAddresses.includes(address) : true)
      .filter(({ shouldSend, status }) =>
        !shouldSend && status !== LetterStatus.Complete && status !== LetterStatus.Requested
      )
      .map(({ _id }) => _id);
    if (addressIds.length === 0) {
      closeConfirmModal();
      return;
    }
    if (selectedBusinessId) {
      const { overcost } = await postSendLettersToAddresses(selectedBusinessId, addressIds);
      if (overcost) {
        setLoading(false);
        closeConfirmModal();
        setShowPricingTable(true);
        return;
      }
    }
    const newAddresses = addresses.map((address) => {
      if (addressIds.includes(address._id)) {
        return {
          ...address,
          shouldSend: true,
        };
      }
      return address;
    });
    setLoading(false);
    setAddresses(newAddresses);
    closeConfirmModal();
  }
  const viewLetter = (): void => {
    if (letterUrl) {
      window.open(letterUrl, "_blank");
    }
  };
  const viewOffer= (): void => {
    if (offerUrl) {
      window.open(offerUrl, "_blank");
    }
  };
  const numAddressesSelected = addressesToDisplay
    .filter((address) => selectedAddresses.includes(address))
    .filter(
      ({ shouldSend, status }) => !shouldSend && status !== LetterStatus.Complete && status !== LetterStatus.Requested
    ).length;
  const totalAvailbleLettersCount = addressesToDisplay.filter(({ shouldSend, status }) =>
    !shouldSend && status !== LetterStatus.Complete && status !== LetterStatus.Requested
  ).length
  const sentAddressesCount = addresses.filter(({ status }) => status === LetterStatus.Complete).length;
  const addressCountText = `${addresses.length} total ${addresses.length === 1 ? 'address' : 'addresses'}, ${sentAddressesCount} sent`;
  const numAddressesSelectedText = numAddressesSelected > 0 ? ` (${numAddressesSelected})` : '';
  const quickSelectRows = (amount: number) => {
    const availableAddresses = addressesToDisplay.filter(address => {
      return !address.shouldSend && address.status !== LetterStatus.Complete && address.status !== LetterStatus.Requested && !selectedAddresses.includes(address);
    });
    setSelectedAddresses([...selectedAddresses, ...availableAddresses.slice(0, amount)]);
  }
  const sendButtonText = numAddressesSelected === 1 ? `Send letter${numAddressesSelectedText}` : `Send letters${numAddressesSelectedText}`;
  return (
    <article className={'find-clients-manage-page'}>
      { confirmModalOpen && <ConfirmSendLettersModal cancel={closeConfirmModal} confirm={sendLetters} numAddressesSelected={numAddressesSelected === 0 ? totalAvailbleLettersCount : numAddressesSelected} /> }
      <section className={'page'}>
        <header className={'find-clients-manage-page-header'}>
          <h2 className={'title'}>{'Manage your outreach'}</h2>
          <h3 className={'subtitle'}>{'View addresses, your messages and confirm send as you see fit'}</h3>
          <section className={'table-buttons'}>
            <AddressFlatfileImport setAddresses={setAddressesAndResetCursor} />
            { letterUrl && <OutlineButton additionalClassNames={['view-letter-button']} content={'View your letter'} onClick={viewLetter} /> }
            { offerUrl && <OutlineButton additionalClassNames={['view-offer-button']} content={'View your offer'} onClick={viewOffer} /> }
            { showSendAllButton && <PrimaryButton additionalClassNames={['send-all-button']} content={sendButtonText} onClick={openConfirmModal} /> }
            <GhostButton additionalClassNames={['show-complete-button']} content={<ShowCompletedToggleButton showCompleted={showCompleted} />} onClick={toggleCompleted} />
          </section>
          <section className={'search-and-filter-bar'}>
            <SearchInput
              placeholder={'Type to search'}
              text={text}
              setText={searchForAddresses}
            />
            <Filter
              rows={addresses}
              filteredRows={addressesToDisplay}
              filters={filters}
              setFilters={setFilters}
              searchText={text}
              filterAttributeMap={AddressFilterAttributeMap}
              filterOptions={AddressFilterAttributeOptions}
            />
            <QuickSelectButton quickSelectRows={quickSelectRows} />
          </section>
          { !loading && <section className={'address-count'}>{addressCountText}</section> }
        </header>
        <section className={'page-content'}>
          {
            loading ? <LoadingRing color={'#000000'} /> : (
              <HandwrittenLettersTable
                addresses={addressesToDisplay}
                setAddresses={setAddresses}
                cursor={cursor}
                setCursor={setCursor}
                selectedAddresses={selectedAddresses}
                setSelectedAddresses={setSelectedAddresses}
                hasAddressesToSend={showSendAllButton}
              />
            )
          }
        </section>
      </section>
    </article>
  );
}

export default LivingNearbyManagePage;