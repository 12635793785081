import './ShowCompletedToggleButton.scss';

import SHOW_ICON from '../../../case-studies/assets/show-completed-icon.svg';
import HIDE_ICON from '../../../case-studies/assets/hide-completed-icon.svg';

interface ShowCompletedToggleButtonProps {
  showCompleted: boolean,
}

function ShowCompletedToggleButton({ showCompleted } : Readonly<ShowCompletedToggleButtonProps>) {
  return (
    <section className={'show-completed-toggle'}>
      <img className={'show-completed-icon'} src={showCompleted ? SHOW_ICON : HIDE_ICON} alt={'Show completed icon'} />
      <span className={'button-text'}>{showCompleted ? 'Showing completed' : 'Hiding completed'}</span>
    </section>
  )
}

export default ShowCompletedToggleButton