import '../common/FollowUpMessage.scss';

import { ReactElement, ChangeEvent } from 'react';

import GhostButton from '../../../common/buttons/GhostButton';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';

interface FollowUpMessageProps {
  text: string,
  setText: (text: string) => void,
  setDaysToWait: (days: number) => void,
  daysToWait: number,
  indexNumber: number,
  deleteMessage: () => void,
}

function getTitleFromIndex(index: number): string {
  if (index === 1) {
    return 'First message';
  };
  if (index === 2) {
    return 'Second message';
  }
  if (index === 3) {
    return 'Third message';
  }
  if (index === 4) {
    return 'Fourth message';
  }
  if (index === 5) {
    return 'Fifth message';
  }
  return '';
}

function FollowUpMessage({ text, setText, daysToWait, setDaysToWait, indexNumber, deleteMessage } : FollowUpMessageProps): ReactElement<FollowUpMessageProps> {
  const daysToWaitText = daysToWait === 1 ? `Wait <b>${daysToWait}</b> day` : `Wait <b>${daysToWait}</b> days`;
  const messageHeaderText = getTitleFromIndex(indexNumber + 1);
  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value);
  const addDays = () => setDaysToWait(daysToWait + 1);
  const removeDays = () => setDaysToWait(daysToWait - 1);
  return (
    <section className={'follow-up-message'}>
      <section className={'days-header'}>
        <section className={'delay-days-selector'}>
          <GhostButton content={'-'} onClick={removeDays} disabled={daysToWait === 0} />
          <span className={'num-days'} dangerouslySetInnerHTML={{ __html: daysToWaitText }}/>
          <GhostButton content={'+'} onClick={addDays} disabled={daysToWait >= 31} />
        </section>
      </section>
      <section className={'message'}>
        <header className={'message-header'}>
          <h4 className={'message-title'}>{messageHeaderText}</h4>
          <TrashIconButton onClick={deleteMessage} />
        </header>
        <textarea
          className={'message-text'}
          value={text}
          onChange={onTextChange}
          placeholder={'Type your message here'}
        />
      </section>
    </section>
  );
}

export default FollowUpMessage;