import { CountryCode, Location } from './GeoTypes';
import { EmailForwardingSettings } from './UserTypes';
import { LeadSource } from './LeadTypes';
import { ReactivateLostClientConditions } from './UserTypes';

export enum AccountBillingTier {
  None = 'None',
  Basic = 'Basic',
  Established = 'Established',
  Scale = 'Scale',
  Enterprise = 'Enterprise',
}

export interface NurtureStep {
  daysToWait: number,
  message: string,
}

export interface NurtureFlowSettings {
  stopNurtureIfResponse: boolean,
  steps: NurtureStep[],
}

export interface LeadNurtureSettings {
  nurtureFlows: {
    [source in LeadSource]: NurtureFlowSettings
  }
}

export enum ActivityType {
  HandwrittenLetter = 'Handwritten letter',
  Reactivation = 'Re-activation',
  FollowUp = 'Follow up',
}

export interface BusinessEvent {
  businessId: string,
  eventType: ActivityType,
  eventDescription: string,
  contextId: string,
  creationDate: number,
}

export enum SubscriptionStatus {
  Active = 'Active',
  PaymentOverdue = 'Payment Overdue',
  Cancelled = 'Cancelled',
  Inactive = 'Inactive',
}

export interface BusinessSubscription {
  status: SubscriptionStatus,
  plan: AccountBillingTier,
  totalCredits: number,
  usedCredits: number,
}

export interface LivingNearbySettings {
  suburbs: string[],
}

export enum ConnectionStatus {
  Connected = 'Connected',
  Error = 'Error',
  Processing = 'Processing',
  NotConnected = 'Not Connected'
}

export interface LinkedInCredentials {
  email: string,
  password: string,
}

export const DEFAULT_LOGIN_CREDENTIALS = {
  email: '',
  password: ''
};

export interface CommercialOutreachType {
  suburbs: string[],
  linkedInCredentials: LinkedInCredentials,
  connectionStatus: ConnectionStatus,
  connectionMessage: string
}

export interface Business extends BusinessDetails {
  _id: string,
  businessLocation: Location,
  countryCode: CountryCode,
  offerUrl: string | null | undefined,
  letterUrl: string | null | undefined,
  programUrl: string | null | undefined,
  messageUrl: string | null | undefined,
  leadToClientProportion: number | undefined,
  revenuePerClient: number | undefined,
  emailForwardingSettings: EmailForwardingSettings,
  leadEmailForwardingSettings?: EmailForwardingSettings,
  livingNearbySettings: LivingNearbySettings | undefined,
  leadNurtureSettings: LeadNurtureSettings,
  reactivateLostClientConditions: ReactivateLostClientConditions[],
  commercialOutreachSettings: CommercialOutreachType,
}

export interface BusinessDetails {
  businessName: string,
  businessAddress: string,
  businessType: string,
  website: string,
  businessContactNumber: string,
}