import './ConnectedLinkedInButton.scss';

import { ReactElement } from 'react';

import useAuth from '../../../../context/AuthContext';

import OutlineButton from '../../../common/buttons/OutlineButton';
import RoundedLetterTag from '../../../common/tags/RoundedLetterTag';
import BlackButton from '../../../common/buttons/BlackButton';
import { ConnectionStatus } from '../../../../types/BusinessTypes';

interface ConnectedLinkedInButtonProps {
  connectionStatus: ConnectionStatus,
  unlinkAccount: () => void,
};

function ConnectedLinkedInButton({ connectionStatus, unlinkAccount } : Readonly<ConnectedLinkedInButtonProps>): ReactElement {
  const { business } = useAuth();
  const connectionStatusText = business && business.businessName 
    ? `${business.businessName}'s LinkedIn Profile ${connectionStatus}`
    : `LinkedIn Profile Connection ${connectionStatus}`
  const connectLinkedInAccountButtonContent = (
    <section className={'linked-in-connected-button'}>
      <RoundedLetterTag content={'A'} />
      <span>{connectionStatusText}</span>
    </section>
  )

  return (
    <section className={'linked-in-connection-buttons'}>
      <OutlineButton content={connectLinkedInAccountButtonContent} />
      <BlackButton content={'Unlink this account'} onClick={unlinkAccount} />
    </section>
  )
}

export default ConnectedLinkedInButton;