import { convert } from 'html-to-text';

export function stripHtml(str: string) {
  return convert(str, {}).trim();
}

export function brToNewLine(str: string) {
  return str.replace(/<br\s*\/?>/mg,'\n');
};

export function autocapitalizeName(name: string) {
  if (!name || name.length === 0) {
    return name;
  }
  return name.replace(/(^\w{1})|(\s+\w{1})|(-+\w{1})/g, letter => letter.toUpperCase());
}

export function capitaliseString(str: string) {
  if (!str || str.length === 0) {
    return str;
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export function capitaliseStringParts(str: string) {
  if (!str) {
    return str;
  }
  const strParts = str.split(' ');
  const capitalisedStrParts = strParts.map(strPart => capitaliseString(strPart));
  return capitalisedStrParts.join(' ');
}